import { Container, Row, Col, Button, Card, Form, FloatingLabel, Modal, Spinner } from "react-bootstrap"
import { Link, Navigate, useNavigate } from "react-router-dom";
import '../../styles/main.css';
import { IconChevronsRight } from '@tabler/icons';
import Content from "./components/content";
import apiClient from "../../services/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import PhoneInput from "react-phone-input-2"
import customLocalStorage from "../../services/customlocalstorage";
import GiveError from "../errors";
import { useSanctum } from "react-sanctum";
import Loading from "../../components/loadPage/loadPage";

function PanelSettings() {

    const { authenticated, user, signOut, checkAuthentication } = useSanctum();


    const [showPasswordChange, setShowPassword] = useState(false);

    const {t} = useTranslation('common');

    const [PassErrorOBJ, setPassErrorOBJ] = useState({
        "old_password": null,
        "password": null,
        "confirm_password": null
    })
    const [isLoad, setLoad] = useState()


    const hidePassword = () => {
        setShowPassword(false);
        setPassErrorOBJ({
            "old_password": null,
            "password": null,
            "confirm_password": null
        })
    }

    const passwordChangeFun = () => {
        setShowPassword(true)
    }
    const [showNameChange, setShowName] = useState(false);

    const [NameErrorOBJ, setNameErrorOBJ] = useState({
        "first_name": null,
        "last_name": null
    })


    const hideName = () => {
        setShowName(false);
        setNameErrorOBJ({
            "first_name": null,
            "last_name": null
        })
    }
    const [showPhoneChange, setShowPhone] = useState(false);

    const [PhoneErrorOBJ, setPhoneErrorOBJ] = useState({
        "phone_number": null
    })


    const hidePhone = () => {
        setShowPhone(false);
        setPhoneErrorOBJ({
            "phone_number": null
        })
    }

    const passwordUpdateData = (val) => {
        setLoad(true)
        const passwd = {
            old_password: val[0].value,
            password: val[1].value,
            confirm_password: val[2].value,
            
        }
        apiClient.post("auth/reset-password", passwd).then((response) => {
            if (response.status === 200) {
                setLoad(false)
                signOut().then(() => {
                    document.location.reload();
                })
            }
            else{
                return GiveError()
            }
        }).catch((err) => {
            if (err.response.status === 422 || err.response.status ===401) {
                setLoad(false);
                setPassErrorOBJ(err.response.data.errors)

              }
              else {
                setLoad(false);
                GiveError()
              }

            return false;
        })
    }

    const updatePersonalData = (val, type) => {
        setLoad(true)
        let updated = {};
        switch (type) {
            case 1:
                updated = {
                    first_name: val[0].value,
                    last_name: val[1].value,
                }
                break;
            case 2:
                updated = {
                    phone_number: val[0].value,
                }
                break;
        }
        apiClient.post("auth/update-details", updated).then((response) => {
            if (response.status === 200) {
                setLoad(false)
                document.location.reload();
            }
        }).catch((err) => {
            if (err.response.status === 422) {
                switch (type) {
                    case 1:
                        setNameErrorOBJ(err.response.data.errors)
                        break;
                    case 2:
                        setPhoneErrorOBJ(err.response.data.errors)
                        break;
                    default:
                        break;
                }
            }
            else{

            }
            setLoad(false)

            return false
        })
    }


    const dataEdit = (value) => {
        switch (value) {
            case "name":
                setShowName(true)
                break;
            case "phone":
                setShowPhone(true)
                break;
            default:
                break;
        }
    }

    return (
        <div>
        {user == null ? <Loading title={t('common:loading')}/> :
        <Container fluid className="p-0">

            <Container className="text-center d-md-block p-0 my-4">
                <p className="mt-5 mb-0 text-center fs-3"><b>{t('account')}</b></p>
                <hr className="rounded mx-auto mb-5 haer" />
            </Container>
            <Container className="">
                    <p>
                        <h5><b>{t('account')}</b></h5> <br />
                    </p>
                    <Container className="p-0 d-flex flex-wrap py-4">
                        <div className="cole col-md-3 col-12 mb-4">
                            <p className="fw-bold" style={{ fontSize: '15px' }}>{t('panel-settings.nameandlname')}</p>
                            <p style={{ fontSize: '15px' }}>{user.first_name ?? '-'} {user.last_name}</p>
                            <a className="fw-bold mt-4" onClick={() => dataEdit("name")} style={{ fontSize: '15px', color: 'var(--main)'}}>{t('panel-settings.change.change')}</a>
                        </div>
                        <div className="cole col-md-3 col-12 mb-4 ">
                            <p className="fw-bold" style={{ fontSize: '15px' }}>Mail</p>
                            <p style={{ fontSize: '15px' }}>{user.email ?? '-'}</p>
                        </div>
                        <div className="cole col-md-3 col-12 mb-4">
                            <p className="fw-bold" style={{ fontSize: '15px' }}>{t('panel-settings.change.phone')}</p>
                            <p style={{ fontSize: '15px' }}>{user.phone_number ?? '-'}</p>
                            <a className="fw-bold mt-4" onClick={() => dataEdit("phone")} style={{ fontSize: '15px', color: 'var(--main)' }}>{t('panel-settings.change.change')}</a>
                        </div>
                    </Container>
                    <Container className="p-0 m-0 my-4">
                        <hr className="my-3" />

                        <Form className="p-0 col-4">
                            <Button className="subBtn2" onClick={() => passwordChangeFun()}>
                                <b style={{ fontSize: '16px' }}>{t('panel-settings.reset')}</b>
                            </Button>
                        </Form>

                        {/* MODAL FOR PASSWORD */}
                        <Modal    size="lg" className="modal-main" show={showPasswordChange} onHide={hidePassword} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
                            <div className='uiBody'>
                                <p className="fw-bold my-4 text-center" style={{ fontSize: '25px' }}>{t('panel-settings.passwd')}</p>
                                <Form className="mt-3 p-0" onSubmit={(e) => {
                                    e.preventDefault();
                                    passwordUpdateData(e.target);
                                }
                                }>
                                    <FloatingLabel label={t('panel-settings.old')}>
                                        <Form.Control type="password" placeholder='old password'  className={PassErrorOBJ["password"] ? 'is-invalid' : ''} />
                                    </FloatingLabel>
                                    <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{PassErrorOBJ["password"]}</p>
                                    <FloatingLabel label={t('panel-settings.new')} className="my-2" >
                                        <Form.Control type="password" placeholder='new password'  className={PassErrorOBJ["old_password"] ? 'is-invalid' : ''} />
                                    </FloatingLabel>
                                    <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{PassErrorOBJ["old_password"]}</p>
                                    <FloatingLabel label={t('panel-settings.new2')} className="mb-3" >
                                        <Form.Control type="password" placeholder='new password' className={PassErrorOBJ["confirm_password"] ? 'is-invalid' : ''} />
                                    </FloatingLabel>
                                    <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{PassErrorOBJ["confirm_password"]}</p>
                                    <Button className="mt-3 subBtn2-canc" onClick={hidePassword}>
                                        <b style={{ fontSize: '16px' }}>{t('cancel')}</b>
                                    </Button>
                                    <Button className="mt-3 subBtn2" type="submit">
                                        {isLoad ? 
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                        <b style={{ fontSize: '16px' }}>{t('panel-settings.reset')}</b>}
                                    </Button>

                                </Form>
                            </div>
                        </Modal>
                        {/* MODAL FOR NAME */}
                        <Modal size="lg" className="modal-main" show={showNameChange} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
                            <div className='uiBody'>
                                <p className="fw-bold my-4 text-center" style={{ fontSize: '25px' }}>{t('panel-settings.change.title-name')}</p>
                                <Form className="mt-3 p-0" style={{ maxWidth: '556px', minWidth: '250px' }} onSubmit={(e) => {
                                    e.preventDefault();
                                    updatePersonalData(e.target, 1)
                                }
                                }>
                                    <FloatingLabel label={t('panel-settings.change.name')} className="" >
                                        <Form.Control value={user.first_name} type="text" placeholder='name' className={NameErrorOBJ["first_name"] ? 'is-invalid' : ''}/>
                                    </FloatingLabel>
                                    <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{NameErrorOBJ["first_name"] ?? NameErrorOBJ["first_name"]}</p>
                                    <FloatingLabel label={t('panel-settings.change.lastname')}  className="my-2" >
                                        <Form.Control value={user.last_name} type="text" placeholder='lname' className={NameErrorOBJ["last_name"] ? 'is-invalid' : ''}/>
                                    </FloatingLabel>
                                    <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{NameErrorOBJ["last_name"] ?? NameErrorOBJ["last_name"]}</p>
                                    <Button className="mt-3 subBtn2-canc" onClick={hideName}>
                                        <b style={{ fontSize: '16px' }}>{t('cancel')}</b>
                                    </Button>
                                    <Button className="mt-3 subBtn2" type="submit">
                                        {isLoad ? 
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            :
                                        <b style={{ fontSize: '16px' }}>{t('panel-settings.change.change')}</b>}
                                    </Button>
                         
                                </Form>
                            </div>
                        </Modal>
                        {/* MODAL FORM PHONE */}
                        <Modal size="lg" className="modal-main" show={showPhoneChange} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
                            <div className='uiBody'>
                                <p className="fw-bold my-4 text-center" style={{ fontSize: '25px' }}>{t('panel-settings.change.phone-title')}</p>
                                <Form className="mt-3 p-0" style={{ maxWidth: '556px', minWidth: '250px' }} onSubmit={(e) => {
                                    e.preventDefault()
                                    updatePersonalData(e.target, 2)
                                }
                                    // onClose();
                                }>
                                    <PhoneInput
                                        specialLabel={t('panel-settings.change.phone')} 
                                        placeholder={t('panel-settings.change.phone')} 
                                        country={'pl'}
                                        regions={'europe'}
                                        containerClass={' mt-3 ' + (PhoneErrorOBJ["phone_number"] != null ? 'is-invalid' : '')}
                                        inputClass={'formInput w-100 ' + (PhoneErrorOBJ["phone_number"] != null ? 'is-invalid' : '')}
                                        countryCodeEditable={false}
                                        disableCountryCode={false}
                                        autoFormat={true}
                                        defaultMask={'... ... ...'}
                                        alwaysDefaultMask={true}
                                        copyNumbersOnly={false}
                                        inputProps={{
                                            id: "form_customer_phone",
                                        }}
                                        localization={customLocalStorage.language.toLowerCase()}
                                        value={user.phone_number} 

                                    />

                                    {PhoneErrorOBJ["phone_number"]?.map(element => {
                                            return <p className="mb-2 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{element}</p>
                                    })}
                                    <Button className="mt-3 subBtn2-canc" onClick={hidePhone}>
                                        <b style={{ fontSize: '16px' }}>{t('cancel')}</b>
                                    </Button>
                                    <Button className="mt-3 subBtn2" type="submit">
                                        {isLoad ? 
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                        <b style={{ fontSize: '16px' }}>{t('panel-settings.change.change')}</b>}
                                    </Button>

                                </Form>
                            </div>
                        </Modal>
                        <hr className="my-3" />
                    </Container>
                    <Container fluid className="p-0 py-4 my-4">
                        <p>
                            <h5><b>{t('panel-settings.delete.header')}</b></h5> <br />
                        </p>
                        <p>{t('panel-settings.delete.label')} {t('panel-settings.delete.label2')} </p>
                    </Container>
            </Container>
            <hr className='m-0 p-0' />
        </Container>
        }
        </div>


    )
}

export default PanelSettings;
