import { Container, Form, Button, FloatingLabel, Spinner } from "react-bootstrap";
import { useState } from "react";
import "../../../styles/main.css";
import apiClient from "../../../services/api";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import GiveError from "../../errors";
import customLocalStorage from "../../../services/customlocalstorage";
import { useTranslation } from "react-i18next";

function NewReset() {

    const { token } = useParams()
    //password validation (are they the same)
    const [password, setPassword] = useState("");
    const [rpassword, setRPassword] = useState("");

    const { t, i18n } = useTranslation('auth');
    const navigate = useNavigate();
  const [isLoad, setLoad] = useState()


    const [errorOBJ, setError] = useState({

        password: null,
        confirm_password: null

    });

    const ifPassword = (pass, rPass) => {
        setPassword(pass)
        setRPassword(rPass)
    }


    //form submition handler
    const handleSubmit = (e) => {
        e.preventDefault();
        //if they're same proceed
        setLoad(true);

            apiClient.post(
                //send values
                "auth/password/reset",
                {
                    password: password,
                    confirm_password: rpassword,
                    token: token,
                    
                }
            ).then(response => {
                if (response.status === 200) {
                    confirmAlert(loginRedirect)
                    
                }
            }).catch(error => {
                setError(error.response.data.errors)
                setLoad(false);
                
            });
    };
    const loginRedirect = {
        customUI: ({ onClose }) => {
            return (
                <div className='uiBody'>
                    <h3>{t("new-password.confirm2.title")}</h3>
                    <p>{t("new-password.confirm2.msg")}</p>
                    <button
                        className="orderSubBtn"
                        onClick={() => {
                            navigate('/login');
                            onClose();
                        }}
                    >
                       {t("new-password.confirm2.btn")}
                    </button>
                </div>
            );
        },
        closeOnEscape: false,
        closeOnClickOutside: false
    }

    return (<Container className="text-center p-0" fluid>
        <p className="mt-5 mb-0 text-center" style={{ fontSize: "32px" }}>
            <b>{t("new-password.label")}</b>
        </p>
        <hr className="rounded mx-auto mb-5 haer" />
        <Form
            style={{ maxWidth: "447px", minWidth: "265px" }}
            className="mx-auto mt-0 p-4"
            onSubmit={handleSubmit}
        >
            <FloatingLabel label={t("new-password.password")} className="mb-1">
                <Form.Control className={errorOBJ["password"] != null ? 'is-invalid' : ''} type="password" placeholder="password" onChange={e => ifPassword(e.target.value, rpassword)} />
            </FloatingLabel>
            <p className="fw-light" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["password"]}</p>
            <FloatingLabel label={t("new-password.repeat-password")} className="mb-1">
                <Form.Control className={errorOBJ["confirm_password"] != null ? 'is-invalid' : ''} type="password" placeholder="repeat password" onChange={e => ifPassword(password, e.target.value)} />
            </FloatingLabel>
            <p className="fw-light" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["confirm_password"]}</p>
            <Button className="mt-5 subBtn" type="submit">
            {isLoad ? 
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                <b style={{ fontSize: "16px" }}>{t("new-password.btn")}</b>}
            </Button>
        </Form>
        <svg
            width="1920"
            height="206"
            viewBox="0 0 1920 190"
            preserveAspectRatio="none"
            className="d-block w-100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 9L994.564 105.552L1938 197H0V9Z" fill="#191563" />
            <g filter="url(#filter0_d_0_1)">
                <path
                    d="M1938 9L943.436 105.552L4.57764e-05 197H1938V9Z"
                    fill="#1D1872"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_0_1"
                    x="-9"
                    y="0"
                    width="1956"
                    height="206"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="4.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_0_1"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_0_1"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    </Container>);
}

export default NewReset;

