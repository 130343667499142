import React from "react";

function Loading(props){
    return(
        <div className="loader">
            <div className="loader-container">
                <div className="loader-anim">
                </div>
                <div className="loader-text">
                    <p>{props.title ?? ''}</p>
                </div>
            </div>
        </div>

    )
}

export default Loading;
