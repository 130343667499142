import { Container, Form, Button, Alert, FloatingLabel, Spinner} from "react-bootstrap";
import { useState } from "react";
import "../../styles/main.css";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../services/api";
import { useTranslation } from "react-i18next";
import GiveError from "../errors";
import customLocalStorage from "../../services/customlocalstorage";
import { useSanctum } from "react-sanctum";
// login form

function Login() {
  const { t, i18n } = useTranslation('auth');
  const [isLoad, setLoad] = useState()

  const [alert, setAlert] = useState(true);
  const [errorMsg, setError] = useState(false);

  // const [isLogged, setIsLogged] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { authenticated, user, signIn } = useSanctum();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    signIn(email, password)
      .then(response => {
          setLoad(false)
          setError(false);
        }
      )
      .catch(
        response => {
          setError(true);
          setLoad(false)
        }
      );
  };
  return (
    <Container className="text-center p-0" fluid>
      <p className="mt-5 mb-0 text-center" style={{ fontSize: "32px" }}>
        <b>{t('login.label')}</b>
      </p>
      <hr className="rounded mx-auto mb-5 haer" />
      <Form
        style={{ maxWidth: "447px", minWidth: "265px" }}
        className="mx-auto mt-0 p-4"
        onSubmit={handleSubmit}
      >

        {/* popup */}

        {alert ? <Alert className="d-flex" style={{ maxWidth: '447px', minWidth: "265px" }}>
          <div className="my-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-lg" viewBox="0 0 16 16">
              <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
            </svg>
          </div>
          <p className="p-0 m-0">{t('login.note')}</p>
          <Button className="alertClose ms-auto" onClick={() => setAlert(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </Button>
        </Alert> : ''}


        {/* error */}

        {errorMsg ? <Alert className="d-flex" style={{ maxWidth: '447px', minWidth: "265px", backgroundColor: 'red' }}>
          <div className="my-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-lg" viewBox="0 0 16 16">
              <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
            </svg>
          </div>
          <p className="p-0 text-center m-auto" style={{ fontSize: '18px' }}>{t('login.error')}</p>
          <Button className="alertClose ms-auto" onClick={() => setError(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </Button>
        </Alert> : ''}

        <FloatingLabel label={t('login.email')} className="mb-3">
          <Form.Control type="email" placeholder="email" onChange={e => setEmail(e.target.value)} />
        </FloatingLabel>
        <FloatingLabel label={t('login.password')}>
          <Form.Control type="password" placeholder="Password" onChange={p => setPassword(p.target.value)} />
        </FloatingLabel>
        <div className="text-start ps-1">
          <Link to="/password/reset" style={{ color: 'var(--main' }} className="m-0 p-0">{t('login.forgot-password')}</Link>
        </div>
        <Button className="mt-5 subBtn" type="submit">
        {isLoad ? 
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <b style={{ fontSize: "16px" }}>{t('login.btn')}</b>}
        </Button>
      </Form>
      <p
        style={{ maxWidth: "400px", minWidth: "265px", fontSize: "20px" }}
        className=" w-25 mx-auto mt-2 fw-bold"
      >
        {t('login.register-note')}
      </p>
      <Link to="/register" style={{ color: "var(--main", fontSize: "20px" }}>
        <p className="fw-bold">{t('login.register')}</p>
      </Link>
      <svg
        width="1920"
        height="206"
        viewBox="0 0 1920 190"
        preserveAspectRatio="none"
        className="d-block w-100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 9L994.564 105.552L1938 197H0V9Z" fill="#191563" />
        <g filter="url(#filter0_d_0_1)">
          <path
            d="M1938 9L943.436 105.552L4.57764e-05 197H1938V9Z"
            fill="#1D1872"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_0_1"
            x="-9"
            y="0"
            width="1956"
            height="206"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_0_1"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_0_1"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Container>
  );
}


export default Login;
