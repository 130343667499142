import { React, Component, useState } from 'react';
import { Row, Col, Card, Button, Container } from 'react-bootstrap';
import { IconChevronsRight } from '@tabler/icons';

import apiClient from '../../../services/api';
import Incoming from './incoming/incoming';
import Realized from './realized/realized';
import { withTranslation } from 'react-i18next';
class Content extends Component {
    constructor (props) {
        super(props)
        this.state= {
            choice: 0,
        }
    }
    render() {
        return (
                <Container className="p-0 d-flex flex-wrap justify-content-center">
                    {/* navigate buttons */}
                    <div className='row justify-content-center'>
                        <Button className="upperbtn" onClick={()=> this.setState({choice: 0})}>
                            <p className="my-auto fw-semibold text-wrap" style={{ fontSize: '20px' }}>{this.props.t('incommingTrips')}</p>
                            {this.state.choice===0 ? <hr className="mt-1 p-0 w-50 mx-auto mr-hr"/> : ''}
                        </Button>
                        <Button className="upperbtn" onClick={()=> this.setState({choice: 1})}>
                            <p className="my-auto fw-semibold text-wrap" style={{ fontSize: '20px' }}>{this.props.t('fullfiledTrips')}</p>
                            {this.state.choice===1 ? <hr className="mt-1 p-0 w-50 mx-auto mr-hr"/> : ''}
                        </Button>

                        </div>
                        <div className='row w-100 justify-content-center'>

                            <div className='col-md-12 col-sm-12 col-lg-8 my-4'>
                                {this.state.choice === 0 ? <Incoming/> : ''}
                                {this.state.choice === 1 ? <Realized /> : ''}
                            </div>
                        </div>

                </Container>

        )
    }
}

export default withTranslation("panel")(Content);
