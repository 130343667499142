import { Container, Form, Button, FloatingLabel } from "react-bootstrap";
import '../../styles/main.css';
import { IconSwitchHorizontal, IconCircle, IconCircleDot } from '@tabler/icons';
import AutoSuggestInput from "../../components/autosuggest/AutoSuggestInput";
import "react-datepicker/dist/react-datepicker.css";
import apiClient from "../../services/api";
import { Link, Navigate, useNavigate, createSearchParams } from "react-router-dom";
import { useContext, useState, createRef, useImperativeHandle } from "react";
import {useTranslation} from "react-i18next";
import {
    CSSTransition,
    TransitionGroup,
    Transition
  } from 'react-transition-group';

function Find() {

    const {t, i18n} = useTranslation('search');

    const navigate = useNavigate();
    const [errorOBJ, setErrorOBJ] = useState({
    })
    const [to, setTo] = useState("");
    const [from, setFrom] = useState("");

    const [toId, setToId] = useState();
    const [fromId, setFromId] = useState();

    const fromInput = createRef();
    const toInput = createRef();

    const handleToValue = (e) => {
        setToId(e)
    }
    const handleFromValue = (e) => {
        setFromId(e)
    }

    const handleToSugg = (e) => {
        setTo(e)
    }
    const handleFromSugg = (e) => {
        setFrom(e)
    }

    const Submitted = (e) => {
        let errors = {}
        if(fromId == undefined || fromId == null){
            errors.fromID = 'To pole nie może byś puste oraz musi posidać poprawną wartość'
        }
        if(toId == undefined || toId === null){
            errors.toID = 'To pole nie może byś puste oraz musi posidać poprawną wartość'

        }
        if(document.getElementById("countPer").value == null || document.getElementById("countPer").value == 0){
            errors.passengers = 'To pole nie może byś puste oraz musi posidać poprawną wartość'
        }
        setErrorOBJ(errors)

        fromInput.current.updateState()
        toInput.current.updateState()

        if(Object.keys(errors).length === 0){
            navigate({
                pathname: "routes",
                search: `?${createSearchParams({
                    fromID : fromId.id,
                    toID : toId.id,
                    passengers : document.getElementById("countPer").value,
                    dep_date : document.getElementById("dataWyj").value
                })}`,
              });
        }

    }
    const SwapInputs = (e) => {
        let fromValue = from
        let toValue = to
        let fromI = fromId
        let toI = toId
        setToId(fromI)
        setFromId(toI)
        setTo(fromValue)
        setFrom(toValue)
        document.getElementById('swaper').classList.toggle('trip-chng')
        setTimeout(() => {  document.getElementById('swaper').classList.toggle('trip-chng'); }, 500);
    }
    return (
        <Container className="text-center p-0" fluid>
            <p className="mt-5 mb-0 fw-bold text-center" style={{ fontSize: '32px' }}>{t('main.label')}</p>
            <hr className="rounded mx-auto haer" />
            <IconCircleDot style={{ color: 'var(--main)' }} /><IconCircle style={{ color: 'var(--main)' }} /><IconCircle style={{ color: 'var(--main)' }} /><IconCircle style={{ color: 'var(--main)' }} />
            <Form style={{ maxWidth: '1000px', minWidth: '200px' }} className="mx-auto my-5 p-4">
                <Container className="d-flex flex-wrap mb-4">

                    <div className="col-lg-5 col-md-5 col-12">
                        <div className="d-flex"><span className="text-start fw-bold p-2 text-uppercase text-muted">{t('from.title')} </span></div>
                        <AutoSuggestInput ref={fromInput} id='from' label={t('from.label')} sugg={from} suggSet={handleFromSugg} fromSet={handleFromValue} from={fromId} to={toId} errors={setErrorOBJ} errorKey="fromID" required
                        />
                        <CSSTransition
                            in={errorOBJ["fromID"]}
                            timeout={500}
                            classNames="input-error"
                            unmountOnExit
                            appear
                        >
                            <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["fromID"]}</p>
                        </CSSTransition>
                    </div>
                    <div className="col-lg-2 col-md-2 col-12">
                    <div className="d-flex"><span className="text-start fw-bold p-2 text-uppercase text-muted">{'\xa0'}</span></div>
                        <Button className="my-auto subBtn2" style={{ width: '55px' }} onClick={()=>SwapInputs()}>
                            <IconSwitchHorizontal id="swaper"/>
                        </Button>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12">
                        <div className="d-flex"><span className="text-start fw-bold p-2 text-uppercase text-muted">{t('to.title')} </span></div>
                        <AutoSuggestInput ref={toInput} id='to' label={t('to.label')} sugg={to} suggSet={handleToSugg} to={fromId} fromSet={handleToValue} from={toId} errors={setErrorOBJ} errorKey="toID" required
                        />
                        <CSSTransition
                            in={errorOBJ["toID"]}
                            timeout={500}
                            classNames="input-error"
                            unmountOnExit
                            appear
                        >
                        <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["toID"]}</p>
                        </CSSTransition>
                    </div>
                </Container>
                <Container className="d-flex flex-wrap mb-5">
                    <FloatingLabel label={t('when.label')} className="mb-3 col-lg-5 col-md-5 col-12">
                        <Form.Control type="date" placeholder="date" id="dataWyj" defaultValue={new Date().toISOString().split('T')[0]} min={new Date().toISOString().split('T')[0]} 
                        className={errorOBJ["date"] != null ? 'is-invalid' : ''}/>
                        <CSSTransition
                            in={errorOBJ["date"]}
                            timeout={500}
                            classNames="input-error"
                            unmountOnExit
                            appear
                        >
                        <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["date"]}</p>
                        </CSSTransition>
                    </FloatingLabel>

                    <div className="ol-lg-2 col-md-2 col-12 "/>
                    <FloatingLabel label={t('passengers.label')} className="col-lg-5 col-md-5 col-12">
                        <Form.Control type="number" min={1} max={9} placeholder="person" id="countPer" className={errorOBJ["passengers"] != null ? 'is-invalid' : ''}/>
                        <CSSTransition
                            in={errorOBJ["passengers"]}
                            timeout={500}
                            classNames="input-error"
                            unmountOnExit
                            appear
                        >
                        <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["passengers"]}</p>
                        </CSSTransition>

                    </FloatingLabel>

                </Container>

                <Button className="mt-5 subBtn" onClick={Submitted} style={{ width: '311px' }}>
                    <b style={{ fontSize: '16px' }}>{t('btn')} </b>
                </Button>
            </Form>
            <svg width="1920" height="206" viewBox="0 0 1920 190" preserveAspectRatio='none' className='d-block w-100' fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 9L994.564 105.552L1938 197H0V9Z" fill="#191563" />
                <g filter="url(#filter0_d_0_1)">
                    <path d="M1938 9L943.436 105.552L4.57764e-05 197H1938V9Z" fill="#1D1872" />
                </g>
                <defs>
                    <filter id="filter0_d_0_1" x="-9" y="0" width="1956" height="206" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                    </filter>
                </defs>
            </svg>
        </Container>
    )
}

export default Find;
