import { Container, Form, Button, FloatingLabel, Alert, Spinner } from "react-bootstrap";
import { useState } from "react";
import "../../styles/main.css";
import apiClient from "../../services/api";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import configData from "../../config.json";
import GiveError from "../errors";

function Register() {
  const { t, i18n } = useTranslation(['auth', 'links']);

  const [isLoad, setLoad] = useState()

  const navigate = useNavigate();
  // react hooks
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [alert2, setAlert2] = useState(false);
  const [info, setInfo] = useState('')

  const [errorOBJ, setErrorOBJ] = useState({
    "email": null,
    "password": null,
    "termsOfService": null,
    "confirm_password": null
  })

  //terms check
  const [isRuleChecked, setRulesCheck] = useState(false)
  const ruleChecked = () => {
    setRulesCheck(!isRuleChecked)
  }
  const [isRodoChecked, setRodoCheck] = useState(false)
  const rodoChecked = () => {
    setRodoCheck(!isRodoChecked)
  }

  //repeat password validation
  const [same, setSame] = useState(true)

  const registerConfirm = {
    customUI: ({ onClose }) => {
      return (
        <div className='uiBody'>
          <h3>{t("register.confirm.header")}</h3>
          <p>{t("register.confirm.label")}</p>
          <button
            className="orderSubBtn"
            onClick={() => {
              navigate('/login');
              onClose();
            }}
          >
            {t("register.confirm.confirm")}
          </button>
        </div>
      );
    },
    closeOnEscape: false,
    closeOnClickOutside: false
  }

  //form submition
  const handleSubmit = (e) => {
    setInfo('')
    setLoad(true);
    e.preventDefault()
      apiClient.post(
        "auth/register",
        {
          //POST values
          email: email,
          password: password,
          confirm_password: rpassword,
          termsOfService: Number(isRuleChecked),
          rodo: Number(isRodoChecked),
        }
      ).then(response => {
        if (response.status === 200) {
          confirmAlert(registerConfirm)
        }
      }).catch(error => {
        setLoad(false);
        if (error.response.status === 422 || error.response.status ===401) {
          setErrorOBJ(error.response.data.errors)
        }
        else{
          GiveError()
        }
      })

  };

  return (
    <Container className="text-center p-0" fluid>
      <p className="mt-5 mb-0 text-center" style={{ fontSize: "32px" }}>
        {" "}
        <b>{t('register.label')}</b>
      </p>
      <hr className="rounded mx-auto haer" />
      <Form style={{ maxWidth: "500px" }} className="w-75 mx-auto mt-2 p-5" onSubmit={handleSubmit}>


        <FloatingLabel label={t('register.email')} className="mt-3">
          <Form.Control className={errorOBJ["email"] != null ? 'is-invalid' : ''} type="email" placeholder="email" onChange={e => setEmail(e.target.value)} required />
        </FloatingLabel>
        {errorOBJ['email']?.map(element => {
          return <p className="mb-2 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{element}</p>
        })}

        <FloatingLabel label={t('register.password')} className="mt-3">
          <Form.Control className={errorOBJ["password"] != null ? 'is-invalid' : ''} type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} required />
        </FloatingLabel>
        {errorOBJ['password']?.map(element => {
          return <p className="mb-2 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{element}</p>
        })}
        <FloatingLabel label={t('register.repeat-password')} className="mt-3">
          <Form.Control className={errorOBJ["confirm_password"] != null ? 'is-invalid' : ''} type="password" placeholder="repeat password" onChange={(e) => setRPassword(e.target.value)} required />
        </FloatingLabel>
        {errorOBJ['confirm_password']?.map(element => {
          return <p className="mb-2 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{element}</p>
        })}

        <Form.Check
          className="text-start mt-3"
          onChange={e => ruleChecked()}
          checked={isRuleChecked}
          label={
            <p className="checkbox">
              <span style={{ color: "red" }}>*</span>{t('register.reg-note')}<span style={{ color: "red" }}>*</span><br />
              <a href={configData.LINKS.REG.URL} style={{ color: "var(--main)" }}>{t('links:link_reg')} </a><br />
              <a href={configData.LINKS.POLICY.URL} style={{ color: "var(--main)" }}>{t('links:link_policy')}</a>
            </p>
          }
        />
        <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["termsOfService"]}</p>

        <Form.Check
          className="text-start"
          onChange={e => rodoChecked()}
          checked={isRodoChecked}
          label={
            <p className="checkbox">
              <span style={{ color: "red" }}>*</span>{t('register.rodo-note')}<br />
              <a href={configData.LINKS.RODO.URL} style={{ color: "var(--main)" }}>{t('links:link_rodo')}</a>
            </p>
          }
        />
        <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{errorOBJ["rodo"]}</p>

        <Button className="mt-3 subBtn" type="submit">
          {isLoad ? 
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <b style={{ fontSize: "16px" }}>{t('register.btn')}</b>}
        </Button>
      </Form>
      <svg
        width="1920"
        height="206"
        viewBox="0 0 1920 190"
        preserveAspectRatio="none"
        className="d-block w-100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 9L994.564 105.552L1938 197H0V9Z" fill="#191563" />
        <g filter="url(#filter0_d_0_1)">
          <path
            d="M1938 9L943.436 105.552L4.57764e-05 197H1938V9Z"
            fill="#1D1872"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_0_1"
            x="-9"
            y="0"
            width="1956"
            height="206"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_0_1"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_0_1"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Container>
  );
}

export default Register;
