import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import customLocalStorage from "./services/customlocalstorage";

import Loading from "./components/loadPage/loadPage";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";


import search_pl from "./translations/pl/search.json";
import search_en from "./translations/en/search.json";
import search_de from "./translations/de/search.json";

import routes_pl from "./translations/pl/routes.json";
import routes_en from "./translations/en/routes.json";
import routes_de from "./translations/de/routes.json";

import order_pl from "./translations/pl/order.json";
import order_en from "./translations/en/order.json";
import order_de from "./translations/de/order.json";


import links_pl from "./translations/pl/links.json";
import links_en from "./translations/en/links.json";
import links_de from "./translations/de/links.json";


import common_pl from "./translations/pl/common.json";
import common_en from "./translations/en/common.json";
import common_de from "./translations/de/common.json";


import auth_pl from "./translations/pl/auth.json";
import auth_en from "./translations/en/auth.json";
import auth_de from "./translations/de/auth.json";

import panel_pl from "./translations/pl/panel.json";
import panel_en from "./translations/en/panel.json";
import panel_de from "./translations/de/panel.json";

import { Sanctum } from "react-sanctum";

import Maintance from "./pages/maintance";
import ServiceData from "./service.json"

i18next.init({
    interpolation: { escapeValue: false },  
    lng: customLocalStorage.language.toLowerCase(),
    fallbackLng: 'pl',
    resources: {
        pl: {
            common: common_pl,
            search: search_pl,
            routes: routes_pl,
            order: order_pl,
            links: links_pl,
            auth: auth_pl,
            panel: panel_pl
        },
        de: {
            common: common_de,
            search: search_de,
            routes: routes_de,
            order: order_de,
            links: links_de,
            auth: auth_de,
            panel: panel_de
        },
        en: {
            common: common_en,
            search: search_en,
            routes: routes_en,
            order: order_en,
            links: links_en,
            auth: auth_en,
            panel: panel_en
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const maintance = ServiceData.maintance ?? true
const sanctumConfig = {
    apiUrl: process.env.REACT_APP_API_BASE_URL,
    csrfCookieRoute: "csrf-cookie",
    signInRoute: "auth/login",
    signOutRoute: "auth/logout",
    userObjectRoute: "user",
  };
root.render(
<I18nextProvider i18n={i18next}>
        <Suspense fallback={(<Loading/>)}>
            <Sanctum config={sanctumConfig}>
                {maintance ? <Maintance/> :
                    <App />
                }
            </Sanctum>
        </Suspense>

</I18nextProvider>
);
