import { React, Component,createRef } from "react";
import { Card } from "react-bootstrap";
import { alignPropType } from "react-bootstrap/esm/types";
import apiClient from "../../../services/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconChevronUp,  IconChevronDown} from "@tabler/icons";
import "swiper/css"
import { Navigation } from "swiper";
import customLocalStorage from "../../../services/customlocalstorage";
import '../../../styles/main.css';
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { StorageContext } from "../../../components/localStorageContext";
class Trips extends Component {

    static contextType = StorageContext

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            curr: props.currency
        }
    }
    onTriger = val => {
        this.props.uid(val.id);
        this.props.current(val);
    }

    disablenavigation = (e, index) => {
        var arrow = document.getElementsByClassName('trips-button-prev')[0];
        if (index <= 2) {
            arrow.classList.add('trips-button-disabled');
            e.allowSlidePrev= false;
          } else {
            arrow.classList.remove('trips-button-disabled');
            e.allowSlidePrev= true;
          }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="trips" >
                <div className="trips-button-prev" id="button-prev"><IconChevronUp size={50}/></div>
                <Swiper
                    slidesPerView={5}
                    centeredSlides = {true}
                    spaceBetween = {40}
                    direction={"vertical"}
                    modules={[Navigation]}
                    loop={false}
                    initialSlide = {2}
                    slideToClickedSlide={true}
                    allowSlidePrev={false}
                    onSlideChange={(e) => {
                        this.onTriger(this.state.data[e.realIndex - 2])
                        this.disablenavigation(e, e.realIndex)
                    }}
                    onInit={(e) => {
                        this.disablenavigation(e, e.realIndex)
                    }}
                    navigation={{
                        nextEl: '.trips-button-next',
                        prevEl: '.trips-button-prev',
                        disabledClass: "trips-button-disabled",
                    }}
                    ref={this.mainSwiper}
                >

                            <SwiperSlide key="23423423123" className=" swiper-no-swiping">
                                <button className="buten swiper-disabled" style={{cursor: 'default'}}>
                                    <Card>
                                        <Card.Body className="row p-4">
                                            <div className="col-6 fw-bold text-start">
                                                <p className="text-start m-0"></p>
                                                <p className="m-0"></p>
                                            </div>
                                            <div className="col-6 fs-6 fw-bold text-center my-auto">
                                                <p className="m-0" style={{color: 'var(--main)'}}>{t('notrip.no')}</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </button>
                            </SwiperSlide>
                            <SwiperSlide key="6465423423234" className="swiper-no-swiping">
                                <button className="buten swiper-disabled" style={{cursor: 'default'}}>
                                    <Card>
                                        <Card.Body className="row p-4">
                                            <div className="col-6 fw-bold text-start">
                                                <p className="text-start m-0"></p>
                                                <p className="m-0"></p>
                                            </div>
                                            <div className="col-6 fs-6 fw-bold text-center my-auto">
                                                <p className="m-0" style={{color: 'var(--main)'}}>{t('notrip.no')}</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </button>
                            </SwiperSlide>
                    {
                        Object.values(this.state.data).map(e => {
                            let date = new Date(Date.parse(e.date));
                            return (
                                <SwiperSlide key={e.id} onClick={this.onTriger.bind(this, e)}>
                                    <button className="buten">
                                        <Card>
                                            <Card.Body className="row p-4">
                                                <div className="col-6 fw-bold text-start">
                                                    <p className="text-start m-0">{date.toLocaleDateString(customLocalStorage.language, { weekday: 'long' })}</p>
                                                    <p className="m-0">{date.toLocaleDateString(customLocalStorage.language, { weekday: 'short' , month:"short", day:"numeric" })}</p>
                                                </div>
                                                <div className="col-6 fs-5 fw-bold text-center my-auto">
                                                    <p className="m-0" style={{color: 'var(--main)'}}>od {e.price[this.context.curr]} {this.context.curr}</p>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </button>
                                </SwiperSlide>
                            )
                        })

                    }
                </Swiper>
                <div className="trips-button-next" id="button-next"><IconChevronDown size={50}/></div>
            </div>
        )
    }
}
export default withTranslation('routes')(Trips);
