import { Navigate } from "react-router-dom"
import { useSanctum } from "react-sanctum"

export const IsLogin = ({children}) => {
    const { authenticated, user} = useSanctum();

    if(authenticated){
        return <Navigate to='/account/trips'/>
    }
    return children
}
