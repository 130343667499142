import { React, Component } from "react"
import { Container, Form } from "react-bootstrap"
import { withTranslation } from "react-i18next";
import StripeLogo from "./../../../images/stripe-logo.png";
import { IconWallet } from "@tabler/icons";
class Payment extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }
    sendARG(val) {
        this.props.payMeth(val.target.value)
    }

    render() {
        const { t } = this.props;
        let error = this.props.error;

        return (
            <Container className="orderForm text-start p-4">
                <p className="my-2 fw-bold" style={{ color: 'var(--main)', fontSize: '24px' }}>{t('payment.label')}</p>

                <label className="orderLabel p-3 container mt-3" for="stripe_radio">
                    <div className="d-flex container" >
                        <div className="col-1  d-flex justify-content-center align-items-center">
                            <Form.Check onClick={(e) => this.sendARG(e)} value={"stripe"} inline type="radio" id="stripe_radio" name="payment" className=" form-checkbox" />
                        </div>
                        <div className="col-8">
                            <p className=" fs-6 my-auto">{t('payment.internet')}</p>
                            <p className="my-auto" style={{fontSize: "12px", color: 'gray'}}>{t("payment.select-label")}</p>
                        </div>
                        <div className="col-3 text-end">
                        </div>
                    </div>
                </label>
                <label className="orderLabel p-3 container mt-3" for="transfer_radio">
                    <div className="d-flex container" >
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <Form.Check onClick={(e) => this.sendARG(e)} value={"transfer"} inline type="radio" id="transfer_radio" name="payment" className=" form-checkbox" />
                        </div>
                        <div className="col-8">
                            <p className="fs-6 my-auto ">{t('payment.classic')}</p>
                            <p className=" my-auto "style={{fontSize: "12px", color: 'gray'}}>{t("payment.select-label2")}</p>
                        </div>
                        <div className="col-3 text-right">
                            
                        </div>
                    </div>
                </label>
                <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{error["payment_method"]}</p>
                <p style={{fontSize: "12px", color: 'gray'}}> {t('payment.e-info')} <b>Stripe</b>. {t("payment.e-info2")}
                </p>
            </Container>

        )
    }
}
export default withTranslation('order')(Payment);
