import { Container, Form, Button, Alert, FloatingLabel, Spinner} from "react-bootstrap";
import { useState } from "react";
import "../../../styles/main.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSanctum } from "react-sanctum";
function LoginComponent(){
    const { t, i18n } = useTranslation('auth');
    const [isLoad, setLoad] = useState()
    const { authenticated, user, signIn } = useSanctum();

    const [alert, setAlert] = useState(true);
    const [errorMsg, setError] = useState(false);

    // const [isLogged, setIsLogged] = useState(false)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        signIn(email, password)
        .then(response => {
            document.location.reload()
            setError(false);
          }
        )
        .catch(
          response => {
            setError(true);
            setLoad(false)
          }
        );
    }
    return (
    <div className="w-100">
        {errorMsg ? <Alert className="d-flex" style={{ maxWidth: '447px', minWidth: "265px", backgroundColor: 'red' }}>
            <div className="my-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-lg" viewBox="0 0 16 16">
                <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
            </svg>
            </div>
            <p className="p-0 text-center m-auto" style={{ fontSize: '18px' }}>{t('login.error')}</p>
            <Button className="alertClose ms-auto" onClick={() => setError(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            </Button>
        </Alert> : ''}

        <Form
        style={{ maxWidth: "500px" }}
        className="mt-0 p-4"
        onSubmit={handleSubmit}
      >
            <FloatingLabel label={t('login.email')} className="mb-3">
                <Form.Control type="email" placeholder="email" onChange={e => setEmail(e.target.value)} />
            </FloatingLabel>
            <FloatingLabel label={t('login.password')}>
                <Form.Control type="password" placeholder="Password" onChange={p => setPassword(p.target.value)} />
            </FloatingLabel>
            <div className="text-start ps-1">
                <Link to="/password/reset" style={{ color: 'var(--main' }} className="m-0 p-0">{t('login.forgot-password')}</Link>
            </div>
            <Button className="mt-5 subBtn" type="submit">
            {isLoad ? 
                <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                <b style={{ fontSize: "16px" }}>{t('login.btn')}</b>}
            </Button>
        </Form>
    </div>);

}
export default LoginComponent
