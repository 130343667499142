import {React, Component} from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import {withTranslation} from "react-i18next";


class CompanyInvoiceForm extends Component {
    constructor(props){
        super()

        this.state = {
            
        }
    }

    render(){
        const { t } = this.props;
        let errorful = this.props.errorObj;
        return(
            <div>
                 <FloatingLabel label={[t('invoice.name'),<span style={{color: 'red'}}>*</span>]} className="mt-3">
                    <Form.Control className={errorful['customer.company.name'] && errorful['customer.company.name'] != null ? 'is-invalid' : ''} type="text" placeholder="txt" id="form_comp_name"/>
                </FloatingLabel>
                <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}> {errorful['customer.company.name'] && errorful['customer.company.name']}</p>
                <FloatingLabel label={[t('invoice.tax'),<span style={{color: 'red'}}>*</span>]} className="mt-3">
                    <Form.Control className={errorful['customer.company.tax'] && errorful['customer.company.tax'] != null ? 'is-invalid' : ''} type="text" placeholder="txt" id="form_comp_tax"/>
                </FloatingLabel>
                <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}> {errorful['customer.company.tax'] && errorful['customer.company.tax']}</p>
            </div>
        )
    }

}
export default withTranslation('order')(CompanyInvoiceForm);
