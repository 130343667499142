import { Container, Button, Card, Row, Col } from "react-bootstrap";
import '../../styles/main.css';
import { IconCircle, IconCircleDot, IconCircleCheck, IconChevronsRight, IconMapPin } from '@tabler/icons';
import {useNavigate, useSearchParams } from "react-router-dom";
import apiClient from "../../services/api";

import Trips from "./components/trips";
import { useState, useContext } from "react";
import TripsMap from "./components/tripsmap";
import { useTranslation } from "react-i18next";
import Loading from "../../components/loadPage/loadPage";
import GiveError from "../errors";
import { confirmAlert } from "react-confirm-alert";
import { StorageContext } from "../../components/localStorageContext";

function Reservation(props) {
    const { curr } = useContext(StorageContext)

    const navigate = useNavigate();
    const { t, i18n } = useTranslation('routes');

    const [tripTitles, setTitles] = useState(props.tripInfo);
    const [tripTitle, settripTitle] = useState(props.toTripName);
    const [isLoad, setLoad] = useState()

    const trips = props.tripsArray
    const poiA = props.poiA
    const poiB = props.poiB

    const [searchParams] = useSearchParams();
    const [tripUUID, setTripUUID] = useState(trips.length > 0 ? trips[0].id : null);
    const [selectedTrip, setSelectedTrip] = useState(trips.length > 0 ? trips[0] : {});


    const submitFunction = () => {
        setLoad(true)
        apiClient.post("/order/new",
            {
                trip_id: tripUUID,
                from_hereid: searchParams.get("fromID"),
                to_hereid: searchParams.get("toID"),
                passengers: searchParams.get("passengers"),
                
            }
        ).then((response) => {
            if(response.status == 200){
                navigate({
                    pathname: "/order/" + response.data.id,
                });
            }
        }).catch(err => {
            setLoad(false)
            return GiveError()
        });
    }
    const hasSubmitted = (cData) => {
        confirmAlert(options);
    }
    const options = {
        customUI: ({ onClose }) => {
            return (
                <div className='uiBody'>
                    <h3 className="m-3 pb-3">{t("main.title")}</h3>
                    {tripCard}
                    <div className="hr"></div>
                    <Row className=" mt-2">
                        <Col className="px-5">
                            <button
                                className="orderCancelBtn m-2"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {t("main.btn")}
                            </button>
                        </Col>
                        <Col className="px-5">
                            <button
                                className="orderSubBtn m-2"
                                onClick={() => {
                                    submitFunction();
                                    onClose();
                                }}
                            >
                                {t("main.confirm")}
                            </button>
                        </Col>
                    </Row>
                </div>
            );
        },
        closeOnEscape: true,
        closeOnClickOutside: true
    }
    const tripCard =               
        <Row className="text-start mt-3">
            <Col sm={12} lg={5} className="d-flex">
            <div className='col-2'><IconMapPin size={40} style={{color: 'var(--main)'}}/></div>
                <div className='col-10'>
                <p>
                    <b>
                        {t('main.from')} {tripTitles.address.county ?? '-'} <br />
                        {tripTitles.title ?? '-'} <br />
                    </b>
                </p>

                <p>
                    {tripTitles.address.state ?? '-'}, {tripTitles.address.countryName?? '-'}
                </p>
                {trips.length > 0 ? <div>
                        <p className="pt-2">
                            <b>
                                {t("main.departure")}
                            </b>
                        </p>
                        <p>
                            {selectedTrip.start.date_start ?? '-'}&nbsp;
                            {selectedTrip.start.time_start ?? '-'}&nbsp;
                            -&nbsp;
                            {selectedTrip.start.time_end ?? '-'}&nbsp;
                        </p>
                    </div>
                : <div></div>}

                </div>
            </Col>
            <Col lg={2} sm={12} className="d-flex justify-content-center">
                <IconChevronsRight style={{ color: "var(--main)" }} size={40} />
            </Col>
            <Col lg={5} sm={12} className="row">
                <div className='col-2'><IconMapPin size={40} style={{color: 'var(--main)'}}/></div>
                <div className='col-10'>
                    <p>
                    <b>
                        {t("main.to")} {tripTitle.address.county} <br />
                        {tripTitle.title} <br />
                    </b>
                    {tripTitle.address.state}, {tripTitle.address.countryName}

                </p>
                </div>
            </Col>
        </Row>
    return (
        isLoad ? 
            <Loading title={t('loading')}/>
        :
        <div>
            <Container className="text-center p-0" fluid>
                <p p className="mt-5 mb-0 fw-bold text-center" style={{ fontSize: '32px' }}>{t('main.label')}</p>
                <hr className="rounded mx-auto haer" />
                <IconCircleCheck style={{ color: 'var(--main)' }} /><IconCircleDot style={{ color: 'var(--main)' }} /><IconCircle style={{ color: 'var(--main)' }} /><IconCircle style={{ color: 'var(--main)' }} />
                <Container className="mt-4 mx-auto row" >
                    {/* DOSTEPNE PRZEJAZDY */}
                    <div style={{'marginBottom': '15vh', 'height': '70vh'}} className="col-sm-12 col-md-4 col-sm-12">
                        {
                        trips.length > 0 ? <Trips data={trips} uid={setTripUUID} current={setSelectedTrip} currency={curr}/> :
                        <div className="fw-bold h-100 d-flex">
                            <p className="my-auto">{t('notrip.header')}</p>
                        </div>
                        }
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <Container className="p-2">
                            <Card className="details mb-2 p-2">
                                <Card.Body>
                                    <Card.Title className="text-start p-2 fw-bold d-inline float-start">{t("notrip.data")}</Card.Title>
                                    <div className="float-start">{tripCard}</div>
                                </Card.Body>
                            </Card>
                        </Container>



                    </div>
                </Container>

                {
                trips.length > 0 ?
                <Button className="subBtn mx-auto my-5 d-flex" onClick={() => hasSubmitted()} style={{ width: '311px' }}>
                    <p className="fw-bold m-auto" style={{ fontSize: '16px' }}>{t('btn')}</p>
                </Button>
                :
                <div></div>
                }

                <svg width="1920" height="206" viewBox="0 0 1920 190" preserveAspectRatio='none' className='d-block w-100' fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 9L994.564 105.552L1938 197H0V9Z" fill="#191563" />
                    <g filter="url(#filter0_d_0_1)">
                        <path d="M1938 9L943.436 105.552L4.57764e-05 197H1938V9Z" fill="#1D1872" />
                    </g>
                    <defs>
                        <filter id="filter0_d_0_1" x="-9" y="0" width="1956" height="206" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="4.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </Container >
        </div>
    )
}

export default Reservation;
