import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { IconAlertTriangle } from "@tabler/icons";
import i18next from "i18next";
function GiveError() {
    const backToLobby = () => {
        document.location.reload()
    }
    const t = i18next.t;

    const errorMSG = {
        customUI: () => {
            return (
                <div className='critErr p-5 '>
                    <div className="d-flex justify-content-center">
                    <p className="my-auto">
                        <IconAlertTriangle style={{color:"red"}} size={40} className="my-3"/> 
                        <h2>{t('common:error.title')}</h2>
                    </p>
                    </div>
                    <p className="my-3">
                        {t('common:error.label')}
                    </p>
                    <p className="my-3">
                        <a style={{color: 'var(--main)'}} className="fs-5 fw-bold" href="mailto:info@mareckitour.pl">info@mareckitour.pl</a>
                    </p>
                    <button
                        className="errorSubBtn w-50"
                        onClick={() => {
                            backToLobby();
                        }}
                    >
                        <p className="fw-bold m-0">{t('common:error.btn')}</p>
                    </button>
                </div>
            );
        },
        closeOnEscape: false,
        closeOnClickOutside: false
    }

    return (
        confirmAlert(errorMSG)
    )

}

export default GiveError;
