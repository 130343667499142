import { useContext, useEffect } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useSanctum } from "react-sanctum";

const RequireLogin = ({children}) => {
    const { authenticated, user, checkAuthentication } = useSanctum();
    let location = useLocation();
    if(authenticated == false){
        return <Navigate to='/login' />
    }
    return children
}

export default RequireLogin;
