import axios from "axios";
import customLocalStorage from "./customlocalstorage";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: true,
  params: {
    lang: customLocalStorage.language
  }
});
export default apiClient;
