import { React, Component } from "react";
import { Container, Form, Button } from "react-bootstrap";
import {withTranslation} from "react-i18next";

class NumberType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: props.slug,
            title: props.title,
            description: props.description,
            passCount: props.persons,
            limit: props.limit,
            price: props.price,
            isSelected: false,
            values: new Array(Number(props.persons)),
        }
    }

    setSwitch = (val) => {
        this.setState({
            isSelected: val.target.checked
        })
        if (!val.target.checked){
            this.props.sendBasket({}, this.state.title)
        }
        this.props.isDelet(val.target.checked, this.props.idx)
        this.state.values.fill(0, 0)
    }

    valueHandler = (idx, value) => {
        if(value){
            if (this.state.values[idx] < this.state.limit){
                this.state.values[idx] = this.state.values[idx] + 1
            }
        }
        else if(!value){
            if(this.state.values[idx] > 0){
                this.state.values[idx] = this.state.values[idx] - 1
            }
        }
        const orderOBJ = { "name": this.state.title, "type": "secondary", "count": this.state.values[idx], "price": this.state.price, "id":`${this.state.slug}_${idx}` }
        this.props.sendBasket(orderOBJ)
        this.forceUpdate()
    }

    render() {
        const { t } = this.props;


        return (
            <Container fluid className="orderLabel mt-3 p-3" style={{ maxwidth: '707px' }}>
                <label className="d-flex">
                    <p className="m-1 fs-6">{this.state.title}</p>
                    <Form.Check className="ms-auto my-auto" type="switch" id={this.state.slug} onChange={(e) => this.setSwitch(e)} />
                </label>
                <div className="m-1" style={{ fontSize: '12px' }}><p>{this.state.description}</p></div>

                {
                    this.state.isSelected ?
                        Array.apply(null, { length: this.state.passCount }).map((e, i) => (
                            <div className="d-flex mx-5 my-2" style={{ maxwidth: '400px' }}>
                                <p className="m-1" style={{ fontSize: '16px' }}>{t('passenger.label')} {i + 1}</p>
                                <div className="ms-auto d-flex text-center option-numeric">
                                    <Button className="simplebtn" onClick={() => this.valueHandler(i, false)}>
                                        -
                                    </Button>
                                    <p className="m-auto fw-bold" id={`${this.state.slug}_${i + 1}`} style={{ fontSize: '16px' }}>{this.state.values[i]}</p>
                                    <Button className="simplebtn" onClick={() => this.valueHandler(i, true)}>
                                        +
                                    </Button>
                                </div>
                            </div>
                        ))
                        :
                        ''
                }
            </Container>
        )
    }

}
export default withTranslation('order')(NumberType);
