import { React, Component } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class BooleanType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: props.slug,
            title: props.title,
            ileBagaz: props.bagaz,
            description: props.description,
            limit: props.limit,
            isBagaz: false,
            values: new Array(Number(props.bagaz)),
        }
    }

    valueHandler = (val, idx) => {
        let cout;
        if (val.target.checked) {
            cout = 1
        }
        else if (!val.target.checked) {
            cout = 0
        }
        const orderOBJ = { "name": this.state.title, "type": "secondary", "count": cout, "price": { "PLN": 20, "EUR": 5 }, "id": `${this.state.slug}_${idx}` }
        this.props.sendBasket(orderOBJ)
        this.forceUpdate()
    }


    setSwitch = (val) => {
        this.setState({
            isBagaz: val.target.checked
        })
        if (!val.target.checked){
            this.props.sendBasket({}, this.state.title)
        }
        this.props.isDelet(val.target.checked, this.props.idx)
        this.state.values.fill(0, 0)
    }
    render() {

        const { t } = this.props;

        return (
            <Container fluid className="orderLabel mt-3 p-3" style={{ maxwidth: '707px' }}>
                <label className="d-flex">
                    <p className="m-1" style={{ fontSize: '16px' }}>{this.state.title}</p>
                    <Form.Check className="ms-auto my-auto" type="switch" onChange={(e) => this.setSwitch(e)} />
                </label>
                <div className="m-1" style={{ fontSize: '12px' }}><p>{this.state.description}</p></div>

                {
                    this.state.isBagaz ?
                        Array.apply(null, { length: this.state.ileBagaz }).map((e, i) => (
                            <div className="d-flex mx-5 my-2" style={{ maxwidth: '400px' }}>
                                <p className="m-1" style={{ fontSize: '16px' }}>{t('passenger.label')} {i + 1}</p>
                                <input className="ms-auto form-check-input form-checkbox" type="checkbox" onClick={(x) => this.valueHandler(x, i)} id={`${this.state.slug}_${i + 1}`} />
                            </div>
                        ))
                        :
                        ''
                }



            </Container>
        )
    }

}
export default withTranslation('order')(BooleanType);
