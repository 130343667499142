import { React, Component } from "react";
import { Form, FloatingLabel } from 'react-bootstrap';
import CompanyInvoiceForm from "./_invoiceForm/company";
import PrivateInvoiceForm from "./_invoiceForm/private";
import {withTranslation} from "react-i18next";

class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: null
        }
    }

    passInvoiceData = (val) => {
        this.setState({ selection: val });
        this.props.isFirma(val);
    }

    render() {
        const { t } = this.props;
        let error = this.props.error

        return (
            <div>
                <Form.Check onClick={(e) => this.passInvoiceData(e.target.value)} value={0} inline type="radio" label={t('invoice.type.private')} name="someradio" defaultChecked/> {/* NAME DO NAZWANIA XD */}
                <Form.Check onClick={(e) => this.passInvoiceData(e.target.value)} value={1} inline type="radio" label={t('invoice.type.company')} name="someradio" className="mb-2" />
                <FloatingLabel label={t('invoice.country.name')} className="mt-3 " >
                    <Form.Select placeholder="txt" id="form_country" className="form-control">
                        <option value="PL">{t('invoice.country.pl')}</option>
                        <option value="DE">{t('invoice.country.de')}</option>
                        <option value="NL">{t('invoice.country.nl')}</option>
                        <option value="BE">{t('invoice.country.be')}</option>
                    </Form.Select>
                </FloatingLabel>
                <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}> {this.props.error["customer.address.country"]}</p>
                
                <FloatingLabel label={[t('invoice.city'),<span style={{color: 'red'}}>*</span>]} className="mt-3" >
                    <Form.Control className={error['customer.address.city'] && error['customer.address.city'][0] != null ? 'is-invalid' : ''} type="text" placeholder="txt" id="form_city" />
                </FloatingLabel>
                <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}>  {this.props.error["customer.address.city"] && this.props.error["customer.address.city"]}</p>
               
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-lg-4">
                        <FloatingLabel label={[t('invoice.postal_code'),<span style={{color: 'red'}}>*</span>]} className="mt-3" >
                            <Form.Control className={error['customer.address.postal'] && error['customer.address.postal'][0] != null ? 'is-invalid' : ''} type="text" placeholder="txt" id="form_postal" />
                        </FloatingLabel>
                        <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}>{this.props.error["customer.address.postal"] && this.props.error["customer.address.postal"]}</p>
                        
                    </div>

                    <div className="ms-auto col-12 col-lg-7">
                        <FloatingLabel label={[t('invoice.number'),<span style={{color: 'red'}}>*</span>]} className="mt-3 ">
                            <Form.Control className={error['customer.address.street'] && error['customer.address.street'][0] != null ? 'is-invalid' : ''} type="text" placeholder="txt" id="form_street" />
                        </FloatingLabel>
                        <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}> {this.props.error["customer.address.street"] && this.props.error["customer.address.street"]}</p>
                        
                    </div>
                </div>
                {
                    this.state.selection == 1 ?
                        <CompanyInvoiceForm errorObj={error}/>
                        :
                        <PrivateInvoiceForm />
                }
            </div>
        )
    }

}

export default withTranslation('order')(Invoice);
