//error component
import { Container } from "react-bootstrap";
import Error from '../images/Error.png'
function NotFound () {
    return (
        <Container className="text-center p-0" fluid>
          <p className="mt-5 mb-0 text-center" style={{ fontSize: "25px" }}>
            Wygląda na to że zabłądziłeś!
          </p>
          <div className="row justify-content-center">
            <img src={Error} className="my-auto p-4" style={{width:'40vh'}} />
          </div>
          <p className="mb-5 mx-auto text-center" style={{ fontSize: "20px", maxWidth: "600px" }}>
            Ta strona nie istnieje, jeżeli uważasz że jest to błąd - skontaktuj się z nami!
          </p>
          <svg
            width="1920"
            height="206"
            viewBox="0 0 1920 190"
            preserveAspectRatio="none"
            className="d-block mt-5 w-100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 9L994.564 105.552L1938 197H0V9Z" fill="#191563" />
            <g filter="url(#filter0_d_0_1)">
              <path
                d="M1938 9L943.436 105.552L4.57764e-05 197H1938V9Z"
                fill="#1D1872"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_0_1"
                x="-9"
                y="0"
                width="1956"
                height="206"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="4.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_0_1"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_0_1"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </Container>
      );
}
export default NotFound;
