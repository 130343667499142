import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/loadPage/loadPage";
import apiClient from "../../../../services/api";
import HasTaken from "./taken";
import Validated from "./validated";
import NotFound from "../../../404";
import GiveError from "../../../errors";
import customLocalStorage from "../../../../services/customlocalstorage";

function Validation() {
    const { token } = useParams();
    const [isLoaded, setLoad] = useState();
    const [isToken, setToken] = useState();

    useEffect(() => {
        apiClient.post("auth/email/verify", {
            token: token,
            
            
        }).then(response => {
            if (response.status === 200) {
                setLoad(true)
                setToken(200)
            }
        }).catch(error => {
            if (error.response.status === 409) {
                setToken(409)
            }
            else if(error.response.status === 404){
                setToken(404)
            }
            else {
                GiveError();
            }
            setLoad(true)
        })
    }, [])
    return (
        <div>
            {
                isLoaded ?
                    (isToken === 200 ?
                        <Validated />
                        :
                        (isToken === 409 ?
                            <HasTaken />
                            :
                            (isToken === 404 ? 
                            < NotFound/> : < NotFound/>)))
                    :
                    <Loading />
            }
        </div>
    )
}
export default Validation;
