import { React, Component } from "react";
import { Form, FloatingLabel } from "react-bootstrap";


class PrivateInvoiceForm extends Component {
    constructor(props) {
        super()

        this.state = {

        }
    }

    render() {

        return (
            <div>
              
            </div>
        )
    }

}
export default PrivateInvoiceForm;