import { React, Component } from "react";
import apiClient from "../../../../services/api";
import customLocalStorage from "../../../../services/customlocalstorage";
import GiveError from "../../../errors";
import BooleanType from "../booleanSlug";
import NumberType from "../numberSlug";


class Option extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passenger: this.props.passenger,
            optionList: []
        }
    }

    componentDidMount() {
        apiClient.get('order/options').then((response) => {
            this.state.optionList = response.data;
            this.forceUpdate();
        }).catch((error)=>{
            if(error.response.status !== 401 && error.response.status !== 422){
                GiveError();
            }
        })
    }

    setReturn = (e, i) => {
        this.props.isEnabled(e, i)
    }

    basketUpdate = (item, type) => {
        this.props.reciveBasket(item, type)
    }

    render() {
        return (
            <div>
                {
                    this.state.optionList.map((e, i) => {
                        if (e.type === "number") {
                            return (
                                <NumberType key={i} idx={i} sendBasket={this.basketUpdate} isDelet={this.setReturn} title={e.name[customLocalStorage.language]} description={e.description[customLocalStorage.language]} slug={e.slug} limit={e.limit} price={e.price} persons={this.state.passenger} />
                            )
                        }
                        else if (e.type === "boolean") {
                            return (
                                <BooleanType key={i} idx={i} sendBasket={this.basketUpdate} isDelet={this.setReturn} title={e.name[customLocalStorage.language]} description={e.description[customLocalStorage.language]} slug={e.slug} price={e.price} bagaz={this.state.passenger} />
                            )
                        }

                    })

                }
            </div>
        )
    }

}

export default Option;
