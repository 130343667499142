import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Loading from "../../../components/loadPage/loadPage";
import apiClient from "../../../services/api";
import Reservation from "../reservation";

function LoadingComp() {
    const [searchParams] = useSearchParams()
    const [reciving, setReciving] = useState([])
    const [git, setGit] = useState(false)
    const [hasLoaded, setLoaded] = useState(false)

    const tripData = {
        dep_date: searchParams.get("dep_date"),
        toID: searchParams.get("toID"),
        fromID: searchParams.get("fromID"),
        passenger: searchParams.get("passengers")
    }

    useEffect(() => {
        apiClient.get("/csrf-cookie").then(() => {
            apiClient.get("/maps/get-trips", {
                params: {
                    from_hereid: tripData.fromID,
                    to_hereid: tripData.toID,
                    departure_date: tripData.dep_date,
                    passengers: tripData.passenger
                }
            }).then((response) => {
                if (response.status === 200) {
                    setReciving(response.data)
                    setGit(true)
                }
                else {
                    setGit(false)
                }
            }).then(() => {
                setLoaded(true)
            }).catch((error) => {
                setLoaded(true)
                setGit(false)
            })
        })
    }, [])


    return (
        <div>
            {
                hasLoaded ?
                    git ?
                        <Reservation {...reciving} />
                        :
                        'tripData'
                    :
                    <Loading/>
            }
        </div>
    )
}

export default LoadingComp;