import React, { Suspense, useState,useContext } from "react";
import { useEffect} from "react";
import { useSearchParams , useParams} from "react-router-dom";
import Loading from "../../components/loadPage/loadPage";
import apiClient from "../../services/api";
import MadeOrder from "./components/madeOrder";
import Order from "./order";
import NotFound from "../404";
import ClosedOrder from "./components/closedOrder";
import customLocalStorage from "../../services/customlocalstorage";
import { useTranslation } from "react-i18next";
import { StorageContext } from "../../components/localStorageContext";
function OrderWrapper() {
    const [orderOBJ, setOrderOBJ] = useState({})
    const { order_id } = useParams();
    const { curr } = useContext(StorageContext)

    const [isLoad, setLoad] = useState()
    const [isValid, setValid] = useState()
    const [isDraft, setDraft] = useState(true)
    const {t} = useTranslation('order');

    useEffect(() => {
        apiClient.get('order/get',
            {
                params: {
                    id: order_id,
                    
                }
            }
        ).then((response) => {
            if (response.status === 200) {
                if(response.data.status != 0){
                    setDraft(false)
                }
                else{
                    setOrderOBJ(response.data)
                    setValid(true)
                }

            }
            else {
                setValid(false)
            }
        }).then(() => {
            setLoad(true)
        }).catch((error) => {
            setLoad(true)
            setValid(false)
        })
    }, [])

    return (

        <div>
            {
             isLoad ? (
                isDraft ? ( isValid ? <Order key={orderOBJ.id} {...orderOBJ} /> : <NotFound/>) : <ClosedOrder/>)
                :
                <Loading title={t('loadingOrder')}/>

            }
        </div>
    )

}

export default OrderWrapper;
