import { Container, Row, Col, Button, Card, Form, FloatingLabel, Navbar } from "react-bootstrap"
import { Link, Navigate, useNavigate } from "react-router-dom";
import '../../styles/main.css';
import { IconChevronsRight } from '@tabler/icons';
import Content from "./components/content";
import apiClient from "../../services/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";


function PanelTrips() {

    const {t} = useTranslation("panel");

    return (
        <Container fluid className="p-0">
            <Container className="text-center d-md-block p-0">
                <p className="mt-5 mb-0 text-center fs-3"><b>{t('tripsTitle')}</b></p>
                <hr className="rounded mx-auto mb-5 haer" />
            </Container>
            <Container className="d-lg-flex mb-4 panel-container">
                <div className="col-12">
                    <Content />
                </div>
            </Container>
            <hr className='m-0 p-0 hrfoot' />
        </Container>
    )
}

export default PanelTrips;
