import { React, Component } from 'react';
import { Card, Row, Col, Button, Collapse } from 'react-bootstrap';
import { IconChevronsRight } from '@tabler/icons';
import getStatus from '../statuses/getStatus';
import customLocalStorage from '../../../../services/customlocalstorage';
class RealizedCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArr: this.props.dataReciv,
        }
    }

    render() {

        return (
            <div>
                {
                    Object.values(this.state.dataArr).map((e, idx) => {
                        let date = new Date(Date.parse(e.trip.date));

                        return (
                            <Card className="p-0 mb-2 shadow-sm border-0" style={{'cursor':'pointer'}} key={idx}>
                            <Card.Body className="p-3">
                                <div className='d-flex flex-wrap row'>
                                    <div className="d-flex col-sm-12 col-md-3">
                                        <p className="my-auto w-100 me-2 py-2 fw-bold" style={{ fontSize: '18px', color: 'var(--main)' }}>{date.toLocaleDateString(customLocalStorage.language, { day: 'numeric', weekday: 'long', month: 'long'})}
</p>
                                        {/* DATA WYJZADU */}
                                    </div>
                                    <div className="d-flex col-sm-12 col-md-6">
                                        <Row>
                                            <Col className="pe-0 d-flex text-center">
                                                <p className="my-auto mx-auto" style={{ fontSize: '13px' }}>{e.place_from ?? '-'}</p>
                                            </Col>
                                            {/* ADRES POCZATKOWY */}
                                            <Col className="p-0 d-flex justify-content-center" sm={1}>
                                                <IconChevronsRight size={40} className="my-auto" style={{ color: 'var(--main)' }} />
                                            </Col>
                                            <Col className="p-0 d-flex text-center" sm={5}>
                                                <p className="my-auto mx-auto" style={{ fontSize: '13px' }}> {e.place_to ?? '-'}</p>
                                            </Col>
                                            {/* ADRES DOCELOWY */}
                                        </Row>
                                    </div>
                                    <div className="d-flex col-sm-12 col-md-3 text-center">
                                        <p className="my-auto mx-auto px-3 fw-bold py-2" style={{ color: 'green', fontSize: '15px' }}>{getStatus(e.status).info}</p>
                                        {/* HERE STATUS PLATNOSCI */}
                                    </div>
                                </div>

                            </Card.Body>
                        </Card>
                        )
                    })

                }
            </div>
        )
    }
}

export default RealizedCard;
