import { React, Component, useState } from 'react';
import { Row, Col, Card, Collapse, Button, Container } from 'react-bootstrap';
import { IconChevronsRight } from '@tabler/icons';
import getStatus from '../statuses/getStatus';
import { Link } from 'react-router-dom';
import customLocalStorage from '../../../../services/customlocalstorage';
import { confirmAlert } from 'react-confirm-alert';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';
import configData from "./../../../../config.json";

class IncomingCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArr: this.props.dataReciv,
            isOpen: new Array,
        }
    }

    componentDidMount() {
        for (let x = 0; x < this.state.dataArr.length; x++) {
            this.state.isOpen.push(false)
        }
        this.forceUpdate()
    }

    stripeConfirm = (url) => {
        confirmAlert({
            customUI: ({ onClose }) => {
            return (
                <div className='uiBody'>
                <h3>{this.props.t("redirectStripe.title")}</h3>
                <p className='py-2'>{this.props.t("redirectStripe.label")} <b>Stripe</b></p>
                <Button
                    className="orderSubBtn"
                    onClick={()=> {
                        onClose()
                        window.open(url, '_blank');
                    }}
                >
                    {this.props.t("redirectStripe.btn")}
                </Button>
                </div>
            );
            },
            closeOnEscape: true,
            closeOnClickOutside: true
        }
        )(url)
    }
    getTricketUrl(order){
        return configData.URL.TICKET_URL + order.id + '/' + order.crypt
    }
    getReceiptUrl(order){
        return configData.URL.RECEIPT_URL + order.id + '/' + order.crypt
    }
    getTicketDetails(e, status, method, idx) {
        switch (status) {
            case 3: return (
                <Row>
                    <div className='d-flex m-0 text-center p-3 col-md-4 col-sm-12'>
                        <p className='d-flex my-auto'>{this.props.t("orderNo")}<br /> {e.number}</p>
                    </div>
                    <div className='text-start m-0 py-2 col-md-4 col-sm-12 '>
                        <p className='m-0'>{this.props.t("payMethod")}:</p><br/>
                        <p>{e.payment.payment_method == 1 ? <b>Stripe</b> : <b>{this.props.t("transfer")}</b>}</p>
                    </div>
                    {(method & method == 1) ?
                    <div className='my-auto ms-auto col-md-4 col-sm-12 text-center'>
                        <p className='fw-bold fs-3' style={{color: 'var(--main)'}}>{e.transaction.sum} {e.transaction.currency.toUpperCase()}</p>
                        <Button className="mx-auto py-1 navBtn text-center d-flex" onClick={() => this.stripeConfirm(e.payment.payment_url)}>
                            <div className="my-auto mx-2">
                                <p style={{ margin: "0px" }}>{this.props.t("payBtn")}</p>
                            </div>
                        </Button>
                    </div>
                    :
                    <div className='my-auto ms-auto col-md-4 col-sm-12'>
                        <p style={{fontSize: '13px'}}>{this.props.t("manualPaymentNote")}</p>
                    </div>
                    }
                </Row>
            );
            case 4 || 5: return (
                <Row>
                    <div className='d-flex m-0 text-center p-3 col-md-4 col-sm-12'>
                        <p className='d-flex my-auto'>{this.props.t("orderNo")}<br /> {e.number}</p>
                    </div>
                    <div className='text-start m-0 py-2 col-md-4 col-sm-12 '>
                        <p className=''>{this.props.t("departureTime")}<br /> {e.scheduledPickUpFrom ? e.scheduledPickUpFrom : "-"}</p>
                    </div>
                    <div className='my-auto ms-auto col-md-4 col-sm-12 text-start'>
                        <a target={"_blank"} className="fw-bold" style={{color: 'var(--main)'}} href={this.getTricketUrl(e)}>{this.props.t("ticketLink")}</a> <br />
                        <a target={"_blank"} className="fw-bold" style={{color: 'var(--main)'}} href={this.getReceiptUrl(e)}>{this.props.t("receiptLink")}</a>
                    </div>
                </Row>
            )
            default: return (
                <p style={{ margin: "0px" }}></p>
            )
        }
    }

    render() {
        return (
            <div>
                {
                    Object.values(this.state.dataArr).map((e, idx) => {
                        let date = new Date(Date.parse(e.trip.date));
                        return (
                            <Card className="p-0 mb-2 shadow-sm border-0" style={{'cursor':'pointer'}} key={idx}>
                                <Card.Body className="p-3"
                                    onClick={() => {
                                        if(!this.state.isOpen[idx]){
                                            this.state.isOpen = []
                                        }
                                        this.state.isOpen[idx] = !this.state.isOpen[idx]
                                        this.forceUpdate()
                                    }}>
                                    <div className='d-flex flex-wrap row'>
                                        <div className="d-flex col-sm-12 col-md-3">
                                            <p className="my-auto w-100 me-2 py-2 fw-bold" style={{ fontSize: '18px', color: 'var(--main)' }}>{date.toLocaleDateString(customLocalStorage.language, { day: 'numeric', weekday: 'long', month: 'long'})}
</p>
                                            {/* DATA WYJZADU */}
                                        </div>
                                        <div className="d-flex col-sm-12 col-md-6">
                                            <Row>
                                                <Col className="pe-0 d-flex text-center">
                                                    <p className="my-auto mx-auto" style={{ fontSize: '13px' }}>{e.place_from ?? '-'}</p>
                                                </Col>
                                                {/* ADRES POCZATKOWY */}
                                                <Col className="p-0 d-flex justify-content-center" sm={1}>
                                                    <IconChevronsRight size={40} className="my-auto" style={{ color: 'var(--main)' }} />
                                                </Col>
                                                <Col className="p-0 d-flex text-center" sm={5}>
                                                    <p className="my-auto mx-auto" style={{ fontSize: '13px' }}> {e.place_to ?? '-'}</p>
                                                </Col>
                                                {/* ADRES DOCELOWY */}
                                            </Row>
                                        </div>
                                        <div className="d-flex col-sm-12 col-md-3 text-center">
                                            <p className="my-auto mx-auto px-3 fw-bold py-2" style={{color:  getStatus(e.status).color, fontSize: '15px' }}>{getStatus(e.status).info}</p>
                                            {/* HERE STATUS PLATNOSCI */}
                                        </div>
                                    </div>
                                    <Collapse in={this.state.isOpen[idx]}>
                                        <div className=''>
                                            {this.getTicketDetails(e, getStatus(e.status).value, e.payment.payment_method, idx)}
                                        </div>
                                    </Collapse>
                                </Card.Body>
                            </Card>
                        )
                    })

                }
            </div>
        )
    }
}

export default withTranslation('panel')(IncomingCard);
