import { React, Component, useState } from 'react';
import { Row, Col, Card, Button, Container } from 'react-bootstrap';
import NoTasks from '../../../images/NoTasks.png'
import { withTranslation } from 'react-i18next';

class NoTrips extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Container className="p-0 h-100">
                <p className='row justify-content-center'><img src={NoTasks} height="200vh" className='w-auto'/></p>
                <p className='row justify-content-center text-center'><h3 className='fw-bold'>{this.props.t('noTrips')}</h3></p>
            </Container>
        )
    }
}

export default withTranslation('panel')(NoTrips);
