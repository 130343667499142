import i18next from "i18next";

function getStatus(stsNum){
    const t = i18next.t;

    switch(stsNum){
        case(3): return({
            info: t("panel:orderStatus.await"),
            value: 3,
            color: "#ff9500"
        })
        case(4): return({
            info: t("panel:orderStatus.accepted"),
            value: 4,
            color: "#33cc33"
        })
        case(5): return({
            info: t("panel:orderStatus.failed"),
            value: 5,
            color: "#ff3300"
        })
        case(8): return({
            info: t("panel:orderStatus.canceled"),
            value: 8,
            color: "#669999"
        })
        case(9): return({
            info: t("panel:orderStatus.refunded"),
            value: 9,
            color: "#666699"
        })
    }
}
export default getStatus;
