import * as React from 'react';
import apiClient from "../../../services/api";
import { useState, useEffect } from 'react';

export default class TripsMap extends React.Component {
  constructor(props) {
    super(props);
  }
  mapRef = React.createRef();
  state = {
    map: null,
  };
  componentDidMount() {
    const H = window.H;
    const platform = new H.service.Platform({
        apikey: "TSFBy1cBFq6fgvJOBd7CmAQn4V6aqdtctnlUNtDtH90"
    });

    const defaultLayers = platform.createDefaultLayers();

    const map = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: { lat: 50, lng: 5 },
        zoom: 4,
        pixelRatio: window.devicePixelRatio || 1
      }
    );
    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    // This variable is unused and is present for explanatory purposes
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    const ui = H.ui.UI.createDefault(map, defaultLayers);
    var yourMarker  =   '/map-marker.svg';
    var icon = new H.map.Icon(yourMarker, {size: {w: 32, h: 32}});

    var from = new H.map.Marker(this.props.poiA, { icon: icon });
    var to = new H.map.Marker(this.props.poiB, { icon: icon });
    var lineString = new H.geo.LineString();
    lineString.pushPoint(this.props.poiA);
    lineString.pushPoint(this.props.poiB);

    window.addEventListener('resize', () => map.getViewPort().resize());
    map.addObjects([from,to, new H.map.Polyline(
      lineString, { style: { lineWidth: 4 }}
    )]);
    this.setState({ map });
    this.forceUpdate()
  }

  componentWillUnmount() {
    // this.state.map.dispose();
  }

  render() {
    return <div ref={this.mapRef} style={{ height: "30vh" }} />;
  }
}
