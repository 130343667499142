import React from "react";
import Autosuggest from "react-autosuggest";
import apiClient from "../../services/api";
import Highlighter from "react-highlight-words";
import { DebounceInput } from "react-debounce-input";
import { Spinner } from "react-bootstrap";
import {
  CSSTransition,
  TransitionGroup,
  Transition
} from 'react-transition-group';

class AutoSuggestInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      label: props.label,
      id: props.id,
      value: props.sugg,
      className: props.className,
      suggestions: [],
      err: <p className="suggestion-error"></p>,
      isLoading: false
    };
  }
  updateState = () => {

  }
  
  loadSuggestions = async (value) => {
    let response = await apiClient
      .get("maps/autosuggest", {
        params: {
          q: value,
          fromCountry: this.props.to ? this.props.to.address.countryCode : '*',
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  };

  // Trigger suggestions
  getSuggestionValue = (suggestion) => suggestion.title;

  // Render suggestion Option
  renderSuggestion = (suggestion) => (
    <Highlighter
      highlightClassName="autosuggest-highlight"
      searchWords={[this.props.sugg]}
      autoEscape={true}
      textToHighlight={suggestion.title}
    />
  );
  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        <div>
          Press Enter to search <strong>{query}</strong>
        </div>
      </div>
    );
  }
  // OnChange event handler
  onChange = (event, { newValue }) => {
    this.props.suggSet(newValue)
  };

  // Suggestion rendering when user types
  onSuggestionsFetchRequested = async ({ value }) => {
    this.state.isLoading = true
    let response = await this.loadSuggestions(value);
    if (response) {
      this.setState({
        suggestions: response.available,
      });
    }
    this.state.isLoading = false
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
    this.props.fromSet(null)
  };

  onTriger = (val) =>{
    this.props.fromSet(val)
  }

  // After suggestion selected
  suggestionSelected = async (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.state.isLoading = true
    let response = await apiClient
      .get("maps/lookup", {
        params: {
          id: suggestion.id
        },
      })
      .then((response) => {
        if (response.data.isValid) {
          this.props.errors(
            prevState => ({
              ...prevState,
              [this.props.errorKey]: ''
          }))
          this.onTriger(response.data.data)
          this.state.isLoading = false
        }
        else {
          this.props.errors(
            prevState => ({
              ...prevState,
              [this.props.errorKey]: response.data.errors
          }))
          this.state.isLoading = false

        }
        return response.data.data;
      });
    this.props.suggSet(response.title)
  };

  render() {
    const { suggestions } = this.state;
    const value = this.props.sugg
    const inputProps = {
      class: "form-control form-control-auto " + this.state.className,
      placeholder: 'enter value',
      value,
      onChange: this.onChange,
    };

    // OnChange event handler
    const renderSearchInput = (inputProps) => (
      <div>
        <div className="form-floating">
          <DebounceInput
            minLength={1}
            debounceTimeout={300}
            {...inputProps}
            className="form-control form-control-auto"
          />
          <label>
            {this.state.label}
          </label>
        </div>
        <CSSTransition
            in={this.state.isLoading}
            timeout={200}
            unmountOnExit
            appear
          >
        <Spinner animation="border" role="status" className="autosuggest-spiner ">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        </CSSTransition>

      </div>
    );
    // Adding AutoSuggest component
    return (
      <div className="position-relative">
        <Autosuggest
          id={this.state.id}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionContainer={this.renderSuggestionContainer}
          onSuggestionSelected={this.suggestionSelected}
          inputProps={inputProps}
          renderInputComponent={renderSearchInput}
          focusInputOnSuggestionClick={false}
          theme={{
            container: 'autosuggest-container',
            containerOpen: 'autosuggest-container--open',
            input: 'autosuggest-input',
            inputOpen: 'autosuggest-input--open',
            inputFocused: 'autosuggest-input--focused',
            suggestionsContainer: 'autosuggest-suggestions-container',
            suggestionsContainerOpen: 'autosuggest-suggestions-container--open',
            suggestionsList: 'autosuggest-suggestions-list',
            suggestion: 'autosuggest-suggestion',
            suggestionFirst: 'autosuggest-suggestion--first',
            suggestionHighlighted: 'autosuggest-suggestion--highlighted',
            sectionContainer: 'autosuggest-section-container',
            sectionContainerFirst: 'autosuggest-section-container--first',
            sectionTitle: 'autosuggest-section-title'
          }}
        />

      </div>

    );
  }
}

export default AutoSuggestInput;
