import { Container, Form, Button, FloatingLabel, Spinner } from "react-bootstrap";
import { useState } from "react";
import "../../styles/main.css";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../services/api";
import { confirmAlert } from "react-confirm-alert";
import {useTranslation} from "react-i18next";
import GiveError from "../errors";
import customLocalStorage from "../../services/customlocalstorage";
//reset form

function Reset() {
  const {t, i18n} = useTranslation('auth');

    const navigate = useNavigate();

    const [errorOBJ, setError]= useState({
      email : ''
    })
    const [email, setEmail] = useState("");
    const [isLoad, setLoad] = useState()
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setLoad(true);

        apiClient.post(
          "auth/password/forget",
          {
            email: email,
            
          }
        ).then(response => {
          if (response.status === 200) {
          confirmAlert(loginRedirect)
          }
        }).catch((error) => {
            setError(error.response.data.errors)
            setLoad(false);
        });
      }
  
    const loginRedirect = {
      customUI: ({onClose}) => {
          return (
            <div className='uiBody'>
              <h3>{t("reset-password.confirm.header")}</h3>
              <p>{t("reset-password.confirm.label")}</p>
              <button
                className="subBtn"
                onClick={() => {
                  navigate('/login');
                  onClose();
                }}
              >
                  {t("reset-password.confirm.btn")}
              </button>
            </div>
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false
  }

    return (
      <Container className="text-center p-0" fluid>
        <p className="mt-5 mb-0 text-center" style={{ fontSize: "32px" }}>
          {" "}
          <b>{t('reset-password.label')}</b>
        </p>
        <hr className="rounded mx-auto mb-5 haer" />
        <Form
          style={{ maxWidth: "447px", minWidth: "265px" }}
          className="mx-auto mt-0 p-4"
          onSubmit={handleSubmit}
        >
          <FloatingLabel label={t('reset-password.email')} className="mb-1">
            <Form.Control className={errorOBJ["email"][0] != null ? 'is-invalid' : ''} type="email" placeholder="email" onChange={e => setEmail(e.target.value)} />
          </FloatingLabel>
          <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}> {errorOBJ["email"]}</p>
          <Button className="mt-2 subBtn" type="submit">
            {isLoad ? 
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            :
            <b style={{ fontSize: "16px" }}>{t('reset-password.btn')}</b>}
          </Button>
        </Form>
        <p
          style={{ maxWidth: "400px", minWidth: "265px", fontSize: "20px" }}
          className="w-25 mx-auto mt-2 mb-1 fw-bold"
        >
          {t('reset-password.note')}
        </p>
        <Link to="/login" style={{ color: "var(--main", fontSize: '20px' }} className="fw-bold">
        {t('reset-password.login')}
        </Link>
        <svg
          width="1920"
          height="206"
          viewBox="0 0 1920 190"
          preserveAspectRatio="none"
          className="d-block w-100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 9L994.564 105.552L1938 197H0V9Z" fill="#191563" />
          <g filter="url(#filter0_d_0_1)">
            <path
              d="M1938 9L943.436 105.552L4.57764e-05 197H1938V9Z"
              fill="#1D1872"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_0_1"
              x="-9"
              y="0"
              width="1956"
              height="206"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_0_1"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_0_1"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </Container>
    );
  }

export default Reset;
