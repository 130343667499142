import { React, Component } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import {withTranslation} from "react-i18next";
import PhoneInput from "react-phone-input-2";
import customLocalStorage from "../../../services/customlocalstorage";

class Passenger extends Component {
    constructor(props) {
        super();
        this.state = {
            passCount: props.psgr,
        }
    }

    render() {
        const { t } = this.props;
        let errors = this.props.error;
        return (
            <div>
                {
                    Array.apply(null, { length: this.state.passCount - 1 }).map((e, i) => (
                        <div key={i}>
                            <p className="my-4 fw-bold" style={{ fontSize: '16px' }}> {t('passenger.label')} {i+2}</p>
                            <Form style={{ maxWidth: '500px' }} className="mt-2">
                                <FloatingLabel label={[t('customer.first_name'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput" >
                                    <Form.Control className={errors[`passengers.${i+1}.first_name`] != null ? 'is-invalid' : ''} type="text" placeholder="placeholder" id={"passenger_"+(i+2)+"_name"}/>
                                </FloatingLabel>
                                <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}> {errors[`passengers.${i+1}.first_name`]}</p>
                                
                                <FloatingLabel label={[t('customer.last_name'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput" >
                                    <Form.Control className={errors[`passengers.${i+1}.last_name`] != null ? 'is-invalid' : ''} type="text" placeholder="placeholder" id={"passenger_"+(i+2)+"_lname"}/>
                                </FloatingLabel>
                                <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}>  {errors[`passengers.${i+1}.last_name`]}</p>
                               
                                <FloatingLabel label={t('customer.email')} className="mt-3 formInput" >
                                    <Form.Control className={errors[`passengers.${i+1}.email`] != null ? 'is-invalid' : ''} type="email" placeholder="txt" id={"passenger_"+(i+2)+"_email"}/>
                                </FloatingLabel>
                                <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}> {errors[`passengers.${i+1}.email`]}</p>
                                <PhoneInput
                                    specialLabel={t('customer.phone_number')}
                                    placeholder={t('customer.phone_number')}
                                    country={'pl'}
                                    regions={'europe'}
                                    containerClass={'mt-3 ' + (errors[`passengers.${i+1}.phone_number`] != null ? 'is-invalid' : '')}
                                    inputClass={'w-100 ' + (errors[`passengers.${i+1}.phone_number`] != null ? 'is-invalid' : '')}
                                    countryCodeEditable={false}
                                    disableCountryCode={false}
                                    autoFormat={true}
                                    defaultMask={'... ... ...'}
                                    alwaysDefaultMask={true}
                                    copyNumbersOnly={false}
                                    inputProps={{
                                        id: `passenger_${i+2}_phone`,
                                        required: true,
                                    }}
                                    localization={customLocalStorage.language.toLowerCase()}

                                />
                                {/* <FloatingLabel label={t('customer.phone_number')} className="mt-3 formInput" >
                                    <Form.Control type="text" placeholder="placeholder" id={"passenger_"+(i+2)+"_phone"}/>
                                </FloatingLabel> */}
                                <p className="mb-4 error-label" style={{ fontSize: '12px', color:'red', textAlign: "left" }}> {errors[`passengers.${i+1}.phone_number`]}</p>
                                
                            </Form>
                        </div>
                    ))

                }



            </div>
        )
    }
}

export default withTranslation('order')(Passenger);

