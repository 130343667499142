import MainBig from '../images/main-big.png'
import MainSmall from '../images/main-small.png'
import TCF from '../images/tcf-logo-cropped.png'
import { IconBrandFacebook, IconX } from '@tabler/icons'

function Maintance(){
    return(
        <div className="vh-100">
            <nav className="navbar">
                <div className="container">
                    <a className="navbar-brand" href="#">
                        <img src={MainBig} alt="" height="24"/>
                    </a>
                </div>
            </nav>
            <div className="d-flex align-items-center justify-content-center h-75">
                <div className="text-center">
                    <h2 className="display-2 fw-bold" style={{color:  "var(--main)"}}>PRZERWA TECHNICZNA</h2>
                    <h3 className="my-5 mx-3">
                        Wprowadzamy zmiany i aktualizacje na stronie, będziemy niedostępni przez chwilę. Niedługo wrócimy!
                    </h3>
                    <h5 className="my-5 mx-3">
                        W tym czasie możesz sprawdzić nasze social-media!
                    </h5>
                    <h5 className="my-5 mx-3">
                        <a href=''>
                            <IconBrandFacebook size={36} className="maintance-icon"/>
                        </a>
                    </h5>
                </div>
            </div>
            <footer className="d-flex flex-wrap align-items-center justify-content-center py-3 my-4">
                <a href="/"
                    className="col-md-12 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                    <img src={MainSmall} height="30" className="mx-2"/>
                    <IconX/>
                    <img src={TCF} height="30" className="mx-2"/>
                </a>
            </footer>
        </div>
    )
}

export default Maintance;
