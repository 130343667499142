import { useState } from "react";
import "../../../styles/main.css";
import { useParams } from "react-router-dom";
import apiClient from "../../../services/api";

import NewReset from "./newpass";
import Loading from "../../../components/loadPage/loadPage";
import NotFound from "../../404";

function Reset2() {
  const [isToken, setToken] = useState();
  const [isLoad, setLoad] = useState(false);
  const { token } = useParams()
  apiClient.post("auth/password/check", {
    token: token
  }).then(response => {
    if (response.status === 200) {
      setToken(true)
      setLoad(true)
    }
  }).catch(error => {
    setToken(false)
    setLoad(true)
  })
  return (
    isLoad ?
      isToken ?
        <NewReset />
        :
        <NotFound  />
      :
      <Loading />
  )
}

export default Reset2;
