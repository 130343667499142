import { useContext, useEffect, useState, useReducer, redirect} from "react";
import { Container, Form, Button, FloatingLabel, Row, Col, Alert } from "react-bootstrap";
import { IconAlarm, IconCircleCheck, IconCircle, IconCircleDot } from '@tabler/icons';
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Option from "./components/_options/options";
import Passenger from "./components/passengers";
import Invoice from "./components/invoiceData";
import apiClient from "../../services/api";
import Payment from "./components/payment";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import React from "react";
import Countdown, { zeroPad } from "react-countdown"
import customLocalStorage from "../../services/customlocalstorage";
import PhoneInput from 'react-phone-input-2'
import '../../styles/main.css';
import '../../styles/phone-number.css';
import { useTranslation } from "react-i18next";
import GiveError from "../errors";
import Loading from "../../components/loadPage/loadPage";
import LoginComponent from "../login/components/login_component";
import configData from "../../config.json";
import { useSanctum } from "react-sanctum";
import {
    CSSTransition,
    TransitionGroup,
    Transition
  } from 'react-transition-group';
import { StorageContext } from "../../components/localStorageContext";
function Order(orderObject) {

    const { t, i18n } = useTranslation(['order', 'auth', 'links', 'auth']);
    const [customerData, setCustomerData] = useState({
    })
    const [passPhone, setPassPhone] = useState()

    const [authType, setAuthType] = useState('continue');
    const { curr } = useContext(StorageContext)

    const { authenticated, user} = useSanctum();

    const [isLoad, setLoad] = useState()
    const [hasLoginError, setLoginError] = useState()
    const navigate = useNavigate();
    const [orderOBJ, setOrderOBJ] = useState(orderObject);
    const [errorOBJ, setErrorOBJ] = useState({
    })

    const [isRuleChecked, setRulesCheck] = useState(false)
    const ruleChecked = () => {
        setRulesCheck(!isRuleChecked)
    }
    const [termsIsRuleChecked, setTermsRulesCheck] = useState(false)
    const termsRulesCheck = () => {
        setTermsRulesCheck(!termsIsRuleChecked)
    }
    const [isRodoChecked, setRodoCheck] = useState(false)
    const rodoChecked = () => {
        setRodoCheck(!isRodoChecked)
    }

    const [optionName, setOptName] = useState();
    const [state, ChangeState] = useState(false)

    const [isFirma, setFirma] = useState(0);

    const [payMethod, setMethod] = useState();

    const [password, setPassword] = useState("");
    const [rpassword, setRPassword] = useState("");

    let passenger = orderOBJ["passengers_count"];
    let order_ID = orderOBJ["id"];

    const [basket, setBasket] = useState([])

    let items
    useEffect(() => {
        items = orderOBJ.transaction.items
        items.forEach(item => {
            setBasket(basket => [{ "name": item.name, "type": "primary", "count": item.count, "price": item.final_price, "id": 0 }])
        })

        for (let i = 0; i < passenger; i++) {

            const statee = Object.assign({}, errorOBJ, {
                [`passenger.${i}.email`]: [null],
                [`passenger.${i}.first_name`]: [null],
                [`passenger.${i}.last_name`]: [null],
                [`passenger.${i}.phone_number`]: [null]
            });
            setErrorOBJ(statee)
        }

        apiClient.get('order/options').then((response) => {
            setOptName(response.data)
            optionName.map((e, i) => {
                available[i] = false
            })
        })
        setCustomerData({
            name:  user ? user.first_name : "",
            lname: user ? user.last_name : "",
            email: user ? user.email : "",
            phone: user ? user.phone_number : ""
        })
        getValueForPassenger('name',user ? user.first_name : "" )
        getValueForPassenger('lname',user ? user.last_name : "" )
        getValueForPassenger('email',user ? user.email : "" )
        setPassPhone(user ? user.phone_number : "" )

    }, [user])

    let optionsValues = []
    let passengerData = []

    const [available, setAvaliable] = useState([])
    const getValueForPassenger = (key, value) => {
        document.querySelector(`#passenger_1_${key}`).value = value
    }

    const getPhoneForPassenger = (key, value) => {
        var element = document.querySelector(`#passenger_1_${key}`)
        element.value = ''
    }
    const CustomDataUpdate = (value, key) => {
        if(key == 'phone'){
            setCustomerData(prevState => ({
                ...prevState,
                [key]: value.target.value
            }))
            if (!state) {
                setPassPhone(value.target.value)
            }
        }
        else{
            setCustomerData(prevState => ({
                ...prevState,
                [key]: value
            }))
            if (!state) {
                getValueForPassenger(key,value)
            }
        }
    }

    const moveState = (e) => {
        ChangeState(e)

        if (state) {
            document.querySelectorAll('[id^="passenger_1_"]').forEach(element => {
                if(element.id == 'passenger_1_phone'){
                    setPassPhone(customerData['phone'])
                }
                else{
                    element.value = customerData[element.id.replace('passenger_1_', '')]
                }
            });
            ChangeState(!state)
        }
    }

    const setOptions = (e, i) => {
        available[i] = e
    }

    const hasSubmitted = (cData) => {
        confirmAlert(options);
    }

    const uploadData = (cData) => {
        setLoad(true)
        optionsValues = []
        for (let i = 0; i < passenger; i++) {
            passengerData[i] = {
                first_name: document.querySelector(`#passenger_${i + 1}_name`).value,
                last_name: document.querySelector(`#passenger_${i + 1}_lname`).value,
                email: document.querySelector(`#passenger_${i + 1}_email`).value,
                phone_number: document.querySelector(`#passenger_${i + 1}_phone`).value
            }
        }

        let addressOBJ = {
            country: document.querySelector('#form_country').value,
            city: document.querySelector("#form_city").value,
            postal: document.querySelector("#form_postal").value,
            street: document.querySelector("#form_street").value
        }
        let customer
        if (isFirma === '1') {
            let companyOBJ = {
                name: document.querySelector("#form_comp_name").value,
                tax: document.querySelector("#form_comp_tax").value
            }
            customer = {
                email: cData['email'],
                first_name: cData['name'],
                last_name: cData['lname'],
                phone_number: cData['phone'],
                address: addressOBJ,
                is_company: 1,
                company: companyOBJ
            }
        }
        else {
            customer = {
                email: document.querySelector("#form_customer_email").value,
                first_name: document.querySelector("#form_customer_name").value,
                last_name: document.querySelector("#form_customer_lname").value,
                phone_number: document.querySelector("#form_customer_phone").value,
                address: addressOBJ,
                is_company: 0
            }
        }
        optionName.map((e, i) => {
            if (available[i] == true) {
                for (let j = 1; j <= passenger; j++) {
                    if (e.type === 'number') {
                        if (document.querySelector(`#${e.slug}_${j}`).innerHTML > 0) {
                            optionsValues.push({
                                count: document.querySelector(`#${e.slug}_${j}`).innerHTML,
                                slug: e.slug,
                                passenger: j - 1
                            })
                        }
                    }
                    else if (e.type === 'boolean') {
                        if (document.querySelector(`#${e.slug}_${j}`).checked) {
                            optionsValues.push({
                                count: 1,
                                slug: e.slug,
                                passenger: j - 1
                            })
                        }

                    }
                }
            }
        }
        )
        if(authType == 'login' && !authenticated){
            setLoad(false)
            setLoginError(true)
        }
        let url = "order/details";
        if(authType == 'register'){
            url = "order/details/register";
        }
        apiClient.post(
            url,
            Object.assign(
                {
                    currency: customLocalStorage.currency,
                    order_id: order_ID,
                    "customer": customer,
                    "passengers": passengerData,
                    payment_method: payMethod,
                    password: password,
                    confirm_password: rpassword,
                    termsOfService: Number(isRuleChecked),
                    rodo: Number(isRodoChecked),
                    orderTermsOfService: Number(termsIsRuleChecked)
                },
                optionsValues.length > 0 ?
                    {
                        options: optionsValues
                    } : {})
        ).then(response => {
            if (response.status === 200) {
                if(response.data.redirect_type == 'outside'){
                    window.location.replace(response.data.redirect_url);
                }
                else if(response.data.redirect_type == 'inside')(
                    window.location.replace('/order/complete/' + response.data.order.id + '/' + response.data.order.crypt)
                )
            }

        }).catch(error => {
            if(error.response.status === 422){
                setErrorOBJ(error.response.data.errors)
                setLoad(false)
                const input =  document.getElementsByClassName("is-invalid")[0];

                if(input){
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start',
                    });
                }
  
            }
            else{
                setLoad(false)
                return GiveError()
            }
        });
        

    }

    const options = {
        customUI: ({ onClose }) => {
            return (
                <div className='uiBody'>
                    <h3>{t('confirm.label')}</h3>
                    <p>
                        {t("confirm.description")}
                    </p>
                    <p className="pb-4">{t('confirm.details')}</p>
                    <Row>
                        <Col className="px-5">
                            <button
                                className="orderCancelBtn"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {t('confirm.cancel')}
                            </button>
                        </Col>
                        <Col className="px-5">
                            <button
                                className="orderSubBtn"
                                onClick={() => {
                                    uploadData(customerData)
                                    onClose();
                                }}
                            >
                                {t('confirm.approve')}
                            </button>
                        </Col>
                    </Row>



                </div>
            );
        },
        closeOnEscape: false,
        closeOnClickOutside: false
    }


    const backToLobby = () => {
        navigate('/')
    }

    const timerLabel = {
        customUI: ({ onClose }) => {
            return (
                <div className='uiBody'>
                    <h2>{t("session.header")}</h2>
                    <p className="my-3">
                        {t("session.info", {beer:<br/>})}
                    </p>
                    <button
                        className="orderSubBtn "
                        onClick={() => {
                            backToLobby()
                            onClose()
                        }}
                    >
                        {t("session.confirm")}
                    </button>
                </div>
            );
        },
        closeOnEscape: false,
        closeOnClickOutside: false
    }


    const rendere = ({ minutes, seconds, completed }) => {
        return <span className="d-inline fs-4 fw-bold align-middle m-auto" style={{ color: "var(--main", verticalAlign: "text-bottom" }}>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
    const basketUpdate = (val, type) => {
        if (!type) {
            let idx = basket.findIndex(x => x.id === val.id);
            let tab = basket
            if (idx != -1) {
                if (val.count === 0) {
                    tab = tab.filter(x=>x.id!==val.id)
                    // tab.splice(idx, 1)
                }
                else {
                    tab[idx] = { "name": val.name, "type": "primary", "count": val.count, "price": val.price, "id": val.id }
                }
                setBasket(tab)
            }
            else {
                if (val.count != 0) {
                    setBasket(basket => [...basket, { "name": val.name, "type": "primary", "count": val.count, "price": val.price, "id": val.id }])
                }
            }
            setBasket(basket => [...basket])
        }
        else if (type){
           basket.forEach((e, i)=>{
                setBasket(basket.filter(x=>x.name !==type))
           })
        }
    }
    const basketSum = () => {
        let total = 0;
        basket.map(item => {
            total += (item.price[curr] * item.count)
        })
        return total;
    }
    return (
        <div>
                {isLoad ? <Loading title={t('loading')}/> : <div></div>}
                <Container className="text-center p-0" fluid>
                    <p className="mt-5 mb-0 fw-bold text-center" style={{ fontSize: '32px' }}>{t('label')}</p>
                    <hr className="rounded mx-auto haer" />
                    <IconCircleCheck style={{ color: 'var(--main)' }} /><IconCircleCheck style={{ color: 'var(--main)' }} /><IconCircleDot style={{ color: 'var(--main)' }} /><IconCircle style={{ color: 'var(--main)' }} />
                    <Container className="w-100 mt-5 mx-auto" style={{ maxwidth: '1300px' }}>
                        <Container className="p-0 d-flex flex-wrap">
                            <Container className="col-lg-8 col-md-7 col-12 order-lg-0 order-md-0 order-1  mb-4">

                                {!authenticated ? <Container className="orderForm text-start mb-4 p-4">
                                    <label className="container" for="continue_account">
                                        <div className="d-flex container" >
                                            <div className="col-1 d-flex justify-content-center align-items-center">
                                                <Form.Check onClick={(e) => setAuthType('continue')} value={"continue"} inline type="radio" id="continue_account" name="account" className="form-checkbox" checked={authType == 'continue'}/>
                                            </div>
                                            <div className="col-11">
                                                <p className="fs-6 my-auto ">{t('auth.continue')}</p>
                                            </div>
                                        </div>
                                    </label>
                                    <label className="container mt-2" for="login_account">
                                        <div className="d-flex container" >
                                            <div className="col-1 d-flex justify-content-center align-items-center">
                                                <Form.Check onClick={(e) => setAuthType('login')} value={"login"} inline type="radio" id="login_account" name="account" className="form-checkbox" checked={authType == 'login'}/>
                                            </div>
                                            <div className="col-11">
                                                <p className="fs-6 my-auto ">{t('auth.login')}</p>
                                            </div>
                                        </div>
                                    </label>
                                    <label className="container mt-2" for="register_account">
                                        <div className="d-flex container" >
                                            <div className="col-1 d-flex justify-content-center align-items-center">
                                                <Form.Check onClick={(e) => setAuthType('register')} value={"register"} inline type="radio" id="register_account" name="account" className="form-checkbox" checked={authType == 'register'}/>
                                            </div>
                                            <div className="col-11">
                                                <p className="fs-6 my-auto ">{t('auth.register')}</p>
                                            </div>
                                        </div>
                                    </label>
                                </Container>
                                : <div></div>}

                                <CSSTransition
                                     in={authType == "login"}
                                     timeout={200}
                                     classNames="login"
                                     unmountOnExit
                                     appear
                                   >
                                    <div>
                                        {hasLoginError ?
                                        <Alert className="d-flex" style={{ backgroundColor: 'red' }}>
                                            <div className="my-auto">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-lg" viewBox="0 0 16 16">
                                                <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                                            </svg>
                                            </div>
                                            <p className="p-0 text-center m-auto" style={{ fontSize: '18px' }}>{t('auth.error')}</p>
                                        </Alert> : '' }
                                        <Container className="orderForm text-start mb-4 p-4">
                                            <p className="mt-2 fw-bold" style={{ color: 'var(--main)', fontSize: '24px' }}>{t('auth.login-label')}</p>
                                            <LoginComponent />
                                        </Container>
                                    </div>
                                </CSSTransition>

                                {/* is logged */}
                                {authenticated ?
                                <Container className="orderForm mb-4 p-4 d-flex ">
                                        <div className="col-1"><IconCircleCheck size={36} color={'green'} className="icon-tabler"/></div>
                                        <div className="col-11 text-start d-flex align-middle"><h5 className="my-auto mx-3">{t('loggedAs')} <b>{user.email}</b></h5></div>
                                </Container>
                                : <div></div>}

                                {/* Rest of order */}
                                <Container className="orderForm text-start mb-4 p-4" >
                                    <p className="mt-2 fw-bold" style={{ color: 'var(--main)', fontSize: '24px' }}>{t('reservation-label')}</p>
                                    <p className="my-4 fw-bold" style={{ fontSize: '16px' }}>{t('customer.label')}</p>
                                    <Form style={{ maxWidth: '500px' }} className="mt-2">
                                        <FloatingLabel label={[t('customer.first_name'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput" >
                                            <Form.Control value={customerData.name} className={errorOBJ["customer.first_name"] != null ? 'is-invalid' : ''} id="form_customer_name" type="text" placeholder="txt" onChange={(e) => CustomDataUpdate(e.target.value, "name")} required />
                                        </FloatingLabel>
                                        <p className="error-label" id="customer.first_name" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["customer.first_name"]}</p>
                                        <FloatingLabel label={[t('customer.last_name'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput" >
                                            <Form.Control value={customerData.lname} className={errorOBJ["customer.last_name"] != null ? 'is-invalid' : ''} id="form_customer_lname" type="text" placeholder="txt" onChange={(e) => CustomDataUpdate(e.target.value, "lname")} required />
                                        </FloatingLabel>
                                        <p className="error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }} required> {errorOBJ["customer.last_name"] && errorOBJ["customer.last_name"]}</p>

                                        <FloatingLabel label={[t('customer.email'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput" >
                                            <Form.Control value={customerData.email} className={errorOBJ["customer.email"] != null || errorOBJ["email"] != null  ? 'is-invalid' : ''} id="form_customer_email" type="email" placeholder="txt" onChange={(e) => CustomDataUpdate(e.target.value, "email")} required />
                                        </FloatingLabel>
                                        <p className="error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {(errorOBJ["customer.email"] && errorOBJ["customer.email"]) || (errorOBJ["email"] && errorOBJ["email"])}</p>

                                        <PhoneInput
                                            specialLabel={[t('customer.phone_number'),<span style={{color: 'red'}}>*</span>]}
                                            placeholder={[t('customer.phone_number'),<span style={{color: 'red'}}>*</span>]}
                                            country={'pl'}
                                            regions={'europe'}
                                            containerClass={' mt-3 ' + (errorOBJ["customer.phone_number"] != null ? 'is-invalid' : '')}
                                            inputClass={'formInput w-100 ' + (errorOBJ["customer.phone_number"] != null ? 'is-invalid' : '')}
                                            countryCodeEditable={false}
                                            disableCountryCode={false}
                                            autoFormat={true}
                                            defaultMask={'... ... ...'}
                                            alwaysDefaultMask={true}
                                            copyNumbersOnly={false}
                                            onChange={(e, a, b, c) => (CustomDataUpdate(b, "phone"))}
                                            inputProps={{
                                                id: "form_customer_phone",
                                            }}
                                            localization={customLocalStorage.language.toLowerCase()}
                                            // value={customerData.phone ?? ''}

                                        />
                                        <p className="error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["customer.phone_number"]}</p>

                                        <CSSTransition
                                            in={authType == "register"}
                                            timeout={200}
                                            classNames="login"
                                            unmountOnExit
                                            appear
                                        >
                                        <div>
                                            <p className="my-4 fw-bold" style={{ fontSize: '16px' }}>{t('auth.label')}</p>

                                            <FloatingLabel label={[t('auth:register.password'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput">
                                                <Form.Control className={errorOBJ["password"] != null ? 'is-invalid' : ''} type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} required />
                                            </FloatingLabel>
                                            <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{errorOBJ['password']}</p>

                                            <FloatingLabel label={[t('auth:register.repeat-password'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput">
                                                <Form.Control className={errorOBJ["confirm_password"] != null ? 'is-invalid' : ''} type="password" placeholder="repeat password" onChange={(e) => setRPassword(e.target.value)} required />
                                            </FloatingLabel>
                                            <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["confirm_password"]}</p>

                                            <Form.Check
                                                className="text-start mt-3"
                                                onChange={e => ruleChecked()}
                                                checked={isRuleChecked}
                                                label={
                                                    <p className="checkbox">
                                                    <span style={{color: 'red'}}>*</span>
                                                    {t('auth:register.reg-note')}<span style={{ color: "red" }}>*</span><br/>
                                                    <a href={configData.LINKS.RODO.URL} style={{ color: "var(--main)" }}>{t('links:link_reg')}</a><br/>
                                                    <a href={configData.LINKS.POLICY.URL} style={{ color: "var(--main)" }}>{t('links:link_policy')}</a><br/>
                                                    </p>
                                                }
                                                />
                                                <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["termsOfService"]}</p>

                                                <Form.Check
                                                className="text-start"
                                                onChange={e => rodoChecked()}
                                                checked={isRodoChecked}
                                                label={
                                                    <p className="checkbox">
                                                    <span style={{color: 'red'}}>*</span>{t('auth:register.rodo-note')}<br/>
                                                    <a href={configData.LINKS.RODO.URL} style={{ color: "var(--main)" }}>{t('links:link_rodo')}</a><br/>
                                                    </p>
                                                }
                                                />
                                                <p className="mb-4 error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{errorOBJ["rodo"]}</p>
                                        </div></CSSTransition>

                                        <p className="mt-5 fw-bold" style={{ fontSize: '16px' }}>{t('invoice.label')}</p>
                                        <Invoice isFirma={setFirma} error={errorOBJ} />
                                        <p style={{ fontSize: '12px' }} className="pt-3">{t('invoice.info')}</p>

                                    </Form>
                                </Container>
                                <Container className="orderForm text-start mb-4 p-4">
                                    <p className="mt-2 fw-bold" style={{ color: 'var(--main)', fontSize: '24px' }}>{t('passengers-label')}</p>
                                    <p className="my-4 fw-bold" style={{ fontSize: '16px' }}> {t('passenger.label')} 1</p>
                                    <Form style={{ maxWidth: '500px' }} className="mt-2">
                                        <FloatingLabel label={[t('customer.first_name'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput" >
                                            <Form.Control className={errorOBJ["passengers.0.first_name"] != null ? 'is-invalid' : ''} type="text" id="passenger_1_name" disabled={!state} placeholder="txt" />
                                        </FloatingLabel>
                                        <p className="error-label" id="passengers.0.first_name" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["passengers.0.first_name"]}</p>

                                        <FloatingLabel label={[t('customer.last_name'),<span style={{color: 'red'}}>*</span>]} className="mt-3 formInput" >
                                            <Form.Control className={errorOBJ["passengers.0.last_name"] != null ? 'is-invalid' : ''} type="text" id="passenger_1_lname" disabled={!state} placeholder="txt" />
                                        </FloatingLabel>
                                        <p className="error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}>{errorOBJ["passengers.0.last_name"]}</p>

                                        <FloatingLabel label={t('customer.email')} className="mt-3 formInput" >
                                            <Form.Control className={errorOBJ["passengers.0.email"] != null ? 'is-invalid' : ''} type="email" id="passenger_1_email" disabled={!state} placeholder="txt" />
                                        </FloatingLabel>
                                        <p className="error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["passengers.0.email"]}</p>
                                        <PhoneInput
                                            specialLabel={t('customer.phone_number')}
                                            placeholder={t('customer.phone_number')}
                                            country={'pl'}
                                            regions={'europe'}
                                            containerClass={'mt-3 ' + (errorOBJ["passengers.0.phone_number"] != null ? 'is-invalid' : '')}
                                            inputClass={'w-100 ' + (errorOBJ["passengers.0.phone_number"] != null ? 'is-invalid' : '')}
                                            countryCodeEditable={false}
                                            disableCountryCode={false}
                                            autoFormat={true}
                                            defaultMask={'... ... ...'}
                                            alwaysDefaultMask={true}
                                            copyNumbersOnly={false}
                                            value={passPhone}
                                            onChange={(e) => setPassPhone(e)}
                                            inputProps={{
                                                id: "passenger_1_phone",
                                                disabled: !state,
                                            }}
                                            localization={customLocalStorage.language.toLowerCase()}

                                        />
                                        <p className="error-label" id="passengers.0.phone_number" style={{ fontSize: '12px', color: 'red', textAlign: "left" }}> {errorOBJ["passengers.0.phone_number"]}</p>

                                    </Form>
                                    <div className="my-2">
                                        <Form.Check id="diffSwitch" type="switch" onChange={(e) => moveState(e.target.checked)} className="d-inline" />
                                        <label for="diffSwitch" className="mx-2 lh-lg d-inline ">{t('passenger.different')}</label>
                                    </div>
                                    <Passenger error={errorOBJ} psgr={passenger} />
                                </Container>
                                <Container className="orderForm text-start mb-4 p-4">
                                    <p className="mt-2 fw-bold" style={{ color: 'var(--main)', fontSize: '24px' }}>{t('options.label')}</p>
                                    <Option isEnabled={setOptions} passenger={passenger} reciveBasket={basketUpdate} />

                                </Container>
                                <Payment error={errorOBJ} payMeth={setMethod} />
                            </Container>
                            <Container className="mb-4 col-lg-4 col-md-5 col-12 order-lg-1 order-md-1 order-0 sticky-md-top divOrderSub">
                                <Container className="orderSub d-flex p-4 col-12">
                                    <div className="col-2 d-flex">
                                        <IconAlarm style={{ color: 'var(--main)' }} className="d-inline align-middle m-auto" size={35} />
                                    </div>
                                    <div className="col-7 d-flex">
                                        <p className="m-auto d-inline fs-6 fw-bold text-start align-middle" style={{ margin: "0", fontSize: "14px", color: "var(--main)" }}>{t('session.label')}</p>
                                    </div>
                                    <div className="col-3 d-flex text-start">
                                        {
                                            <Countdown
                                                date={orderOBJ["draft_reservation_expiration"]}
                                                renderer={rendere}
                                                onComplete={() => confirmAlert(timerLabel)}
                                            />
                                        }
                                    </div>
                                </Container>
                                <Container className="orderSub mt-4">
                                    <Container className="p-3">
                                        <p className="my-2 fw-bold fs-6 text-left" style={{color: 'var(--main)' }}>{t('your-reservation')}</p>
                                        <div className="d-flex mt-3">
                                            <div className="m-0 p-0 me-3" style={{ border: '1px solid var(--main)', opacity: '1' }} />
                                            <div style={{ textAlign: "left" }}>
                                                <p className="fw-normal fs-6">{orderOBJ.place_from ?? '-'}</p> <br />
                                                <p className="fw-normal fs-6">{orderOBJ.place_to ?? '-'}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="basket">
                                            {
                                                basket.map(item => {
                                                    return (
                                                        <div className="d-flex">
                                                            <p className="fw-bolder">{item.count} x {item.name}</p>
                                                            <p className="ms-auto fw-bolder basket-price">{item.price[curr] * item.count} {curr}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <hr className="m-0" />
                                        <div className="d-flex py-3">
                                            <p className="fw-bolder fs-5">{t('basket.sum')}</p>
                                            <p className="ms-auto fs-5 fw-bolder basket-price">{basketSum()} {curr}</p>
                                        </div>
                                        <Form.Check
                                            className="text-start mt-3"
                                            onChange={e => termsRulesCheck()}
                                            checked={termsIsRuleChecked}
                                            label={
                                                <p className="checkbox">
                                                {t('auth:register.reg-note')}<span style={{ color: "red" }}>*</span><br/>
                                                <a href={configData.LINKS.RODO.URL} style={{ color: "var(--main)" }}>{t('links:link_reg')} </a><br/>
                                                <a href={configData.LINKS.POLICY.URL} style={{ color: "var(--main)" }}>{t('links:link_policy')}</a><br/>
                                                </p>
                                            }
                                            />
                                        <p className="error-label" style={{ fontSize: '12px', color: 'red', textAlign: "left" }} required> {errorOBJ["orderTermsOfService"] && errorOBJ["orderTermsOfService"]}</p>
                                        <div>
                                            <Button onClick={() => hasSubmitted()} className="my-3 subBtn">
                                                <b style={{ fontSize: "16px" }}>{t('btn')}</b>
                                            </Button>
                                        </div>
                                    </Container>
                                </Container>
                            </Container>

                        </Container>

                    </Container>

                    <svg width="1920" height="200" viewBox="0 0 1920 190" preserveAspectRatio='none' className='d-block w-100' fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 9L994.564 105.552L1938 197H0V9Z" fill="#191563" />
                        <g filter="url(#filter0_d_0_1)">
                            <path d="M1938 9L943.436 105.552L4.57764e-05 197H1938V9Z" fill="#1D1872" />
                        </g>
                        <defs>
                            <filter id="filter0_d_0_1" x="-9" y="0" width="1956" height="206" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset />
                                <feGaussianBlur stdDeviation="4.5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </Container>
        </div>

    )
}

export default Order;
