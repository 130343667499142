import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/main.css";
import MareckiMain from "./images/main-big.png";
import MareckiNeg from "./images/negative-big.png";
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Row, Col, Container, Offcanvas, Button } from "react-bootstrap";
import { useContext, useEffect, useState, useTransition } from "react";
import i18next from "i18next";

import configData from "./config.json";

import OrderWrapper from "./pages/order/order_wrapper";

import Find from "./pages/order/available";

// login components
import Login from './pages/login/login'
import Register from './pages/login/register'
import Reset from './pages/login/reset'
import Reset2 from './pages/login/auth/auth'

import PanelTrips from "./pages/panel/panelTrips";
import PanelSettings from "./pages/panel/panelSettings";

import React from "react";
import { IconUser, IconCurrencyDollar, IconLanguage, IconCurrencyZloty, IconCurrencyEuro, IconLogout, IconUserSearch, IconTicket, IconSettings, IconBus} from "@tabler/icons";
import { IsLogin } from "./pages/login/auth/isLogin";
import { useTranslation } from "react-i18next";
import LoadingComp from "./pages/order/components/hasLoadedRes";
import Validation from "./pages/login/verify/email/emailVali";
import Flag from 'react-world-flags'
import TripsWrapper from "./pages/order/trips_wrapper";
import MadeOrder from "./pages/order/components/madeOrder";
import ManualOrder from "./pages/order/components/manualOrder";
import NotFound from "./pages/404";
import { useSanctum } from "react-sanctum";
import Loading from "./components/loadPage/loadPage";
import RequireLogin from './pages/login/auth/requireLogin'
import HasTaken from "./pages/login/verify/email/taken";
import FailedOrder from "./pages/order/components/failedOrder";
import ServiceData from "./service.json"
import customLocalStorage from "./services/customlocalstorage";
import { StorageContext } from "./components/localStorageContext";

function App() {
  const version = process.env.REACT_APP_VERSION;
  const { t, i18n } = useTranslation(['links', 'common']);

  const { authenticated, user, signIn, signOut, checkAuthentication} = useSanctum();
  //mobile offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //currency update and local storage
  const [curr, changeCurr] = useState("PLN");
  const currUpdate = (val) => {
    localStorage.setItem("currency", val);
    changeCurr(localStorage.getItem("currency"));
  };

  //language update and local storage
  const [Lang, changeLang] = useState("PL");
  const langUpdate = (val) => {
    localStorage.setItem("language", val);
    changeLang(localStorage.getItem("language"));
    i18n.changeLanguage(val.toLowerCase());
    window.location.reload(false);
  };

  //load storage data
  useEffect(() => {
    if (localStorage.getItem("language") != null && localStorage.getItem("currency") != null) {
      changeLang(localStorage.getItem("language"));
      changeCurr(localStorage.getItem("currency"));
    } else {
      changeLang("PL");
      localStorage.setItem("language", "PL");
      changeCurr("PLN");
      localStorage.setItem("currency", "PLN");
    }
  });
  const getMaintain = () =>{
    if(ServiceData.maintanceShedule && ServiceData.maintanceShedule !== false){
      return new Date(ServiceData.maintanceShedule)
    }
  }
  return (
    <StorageContext.Provider
    value={{ curr, changeCurr }}
  >
      <Router>
        {authenticated == null &&  <Loading title={t('common:loading')}/>}
        {(ServiceData.maintanceShedule && ServiceData.maintanceShedule !== false) ?
          <div className="maitain-baner fw-bold py-3 px-5">
   
            {t('common:maintance', {date: getMaintain().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})})}
          </div>
        : ''
        }
        <Navbar
          style={{
            backgroundColor: "var(--main)",
            boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
          }}
          variant="dark"
        >
          <Container>
            <Navbar.Brand href="/">
              { }
              <img src={MareckiMain} alt="" style={{ height: "30px" }} />
            </Navbar.Brand>
            <Nav>
              <Button
                className="d-md-none upperbtn"
                style={{ width: "auto" }}
                onClick={handleShow}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="white"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </Button>
            </Nav>
          </Container>
        </Navbar>
        <Navbar
          style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
          className="d-none d-md-block"
        >
          {/* MOBILE */}
          <Offcanvas
            show={show}
            className="d-block d-md-none"
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>  <img src={MareckiMain} alt="" style={{ height: "30px" }} /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <Container className="d-flex mb-4">
                <p
                  className="my-auto fw-semibold me-2"
                  style={{ fontSize: "16px" }}
                >
                  {t('common:account-settings')}
:
                </p>
                <NavDropdown
                title={
                  <span className="Marecki">
                    <IconLanguage
                      size={20} // set custom `width` and `height`
                      className="d-inline"/>
                  <p className="d-inline p-1 fw-bolder">{Lang}</p>
                  </span>
                }
                className="my-auto nav-dropdown"
                onSelect={langUpdate}
              >
                <NavDropdown.Item eventKey="PL"><Flag code={'PL'} height="16" className="nav-dropdown-icon"/><span className="nav-dropdown-text">PL</span></NavDropdown.Item>
                <NavDropdown.Item eventKey="EN"><Flag code={'SH'} height="14" className="nav-dropdown-icon"/><span className="nav-dropdown-text">EN</span></NavDropdown.Item>
                <NavDropdown.Item eventKey="DE"><Flag code={'DE'} height="16" className="nav-dropdown-icon"/> <span className="nav-dropdown-text">DE</span></NavDropdown.Item>
              </NavDropdown>
                <div className="vr m-1" />
                <NavDropdown
                title={
                  <span className="Marecki">
                    <div className="d-inline">
                    <IconCurrencyDollar
                      size={20}
                    />
                    </div>
                    <p className="d-inline p-1 fw-bolder">{curr}</p>
                  </span>
                }
                className="my-auto nav-dropdown"
                onSelect={currUpdate}
              >
                <NavDropdown.Item eventKey="PLN">
                <IconCurrencyZloty
                      size={20}
                    className="nav-dropdown-icon icon-tabler"/><span className="nav-dropdown-text">PLN</span></NavDropdown.Item>
                <NavDropdown.Item eventKey="EUR">
                <IconCurrencyEuro
                      size={20}
                    className="nav-dropdown-icon icon-tabler"/><span className="nav-dropdown-text">EUR</span></NavDropdown.Item>
              </NavDropdown>
              </Container>

              {
                authenticated === true ? <div>
              <Nav.Link
                className="Offcanv d-flex my-2"
                onClick={handleClose}
                as={Link}
                to="/account/trips"
              >
                <IconBus
                    size={35}
                  className="icon-tabler"/>
                <p className="my-auto ms-3 fw-bold" style={{ fontSize: "20px" }}>
                  {t('common:reservations')}
                </p>
              </Nav.Link>
              <Nav.Link
                className="Offcanv d-flex my-2"
                onClick={handleClose}
                as={Link}
                to="/account/settings"
              >
                <IconSettings
                    size={35}
                  className="icon-tabler"/>
                <p className="my-auto ms-3 fw-bold" style={{ fontSize: "20px" }}>
                {t('common:account-settings')}
                </p>
              </Nav.Link>
              <Nav.Link
                className="Offcanv d-flex logout-btn my-2"
                onClick={()=>{signOut()}}
              >
                <IconLogout
                    size={35}
                  className="icon-tabler"/>
                <p className="my-auto ms-3 fw-bold" style={{ fontSize: "20px" }}>
                Wyloguj
                </p>
              </Nav.Link>
            </div>
            :   <Container className="w-100 mt-4">
                 <Nav.Link
                className="Offcanv d-flex my-2"
                onClick={()=>{signOut()}}
              >
                <p className="my-auto fw-bold" style={{ fontSize: "20px" }}>
                {t('common:login')}
                </p>
              </Nav.Link>
              </Container>
            }
              <Container className="w-100 mt-4">
                <a className="nav-link Offcanv" href={configData.LINKS.LINK_1.URL}>{t('link_1')}</a>
                <a className="nav-link Offcanv" href={configData.LINKS.LINK_2.URL}>{t('link_2')}</a>
                <a className="nav-link Offcanv" href={configData.LINKS.LINK_3.URL}>{t('link_3')}</a>
              </Container>
            </Offcanvas.Body>
          </Offcanvas>
{/* MOBILN't */}


          <Container className="d-none d-md-flex">
            <Nav className="me-auto">
              <a className="nav-link" href={configData.LINKS.LINK_1.URL}>{t('link_1')}</a>
              <div className="vr my-1 mx-4" />
              <a className="nav-link" href={configData.LINKS.LINK_2.URL}>{t('link_2')}</a>
              <div className="vr my-1 mx-4" />
              <a className="nav-link" href={configData.LINKS.LINK_3.URL}>{t('link_3')}</a>
            </Nav>
            <Nav>
              <div className="vr m-1" />
              <NavDropdown
                title={
                  <span className="Marecki">
                    <IconLanguage
                      size={20} // set custom `width` and `height`
                      className="d-inline"/>
                  <p className="d-inline p-1 fw-bolder">{Lang}</p>
                  </span>
                }
                className="my-auto nav-dropdown"
                onSelect={langUpdate}
              >
                <NavDropdown.Item eventKey="PL"><Flag code={'PL'} height="16" className="nav-dropdown-icon"/><span className="nav-dropdown-text">PL</span></NavDropdown.Item>
                <NavDropdown.Item eventKey="EN"><Flag code={'SH'} height="14" className="nav-dropdown-icon"/><span className="nav-dropdown-text">EN</span></NavDropdown.Item>
                <NavDropdown.Item eventKey="DE"><Flag code={'DE'} height="16" className="nav-dropdown-icon"/> <span className="nav-dropdown-text">DE</span></NavDropdown.Item>
              </NavDropdown>
              <div className="vr m-1" />
              <NavDropdown
                title={
                  <span className="Marecki">
                    <div className="d-inline">
                    <IconCurrencyDollar
                      size={20}
                    />
                    </div>
                    <p className="d-inline p-1 fw-bolder">{curr}</p>
                  </span>
                }
                className="my-auto nav-dropdown"
                onSelect={currUpdate}
              >
                <NavDropdown.Item eventKey="PLN">
                <IconCurrencyZloty
                      size={20}
                    className="nav-dropdown-icon icon-tabler"/><span className="nav-dropdown-text">PLN</span></NavDropdown.Item>
                <NavDropdown.Item eventKey="EUR">
                <IconCurrencyEuro
                      size={20}
                    className="nav-dropdown-icon icon-tabler"/><span className="nav-dropdown-text">EUR</span></NavDropdown.Item>
              </NavDropdown>
              <div className="vr m-1" />
              {
                authenticated === true ? <NavDropdown
                title={
                  <span className="Marecki">
                    <div className="d-inline">
                    <IconUser
                  size={26}
                />
                    </div>
                  </span>
                }
                align="end"
                className="my-auto nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/account/trips" className="text-start">
                  <IconTicket
                      size={20}
                    className="nav-dropdown-icon icon-tabler"/><span className="nav-dropdown-text">{t('common:reservations')}</span>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/account/settings" className="text-start">
                  <IconUserSearch
                      size={20}
                    className="nav-dropdown-icon icon-tabler"/><span className="nav-dropdown-text">{t('common:account-settings')}</span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={()=>{signOut()}} className="logout-btn text-start">
                  <IconLogout
                      size={20}
                    className="nav-dropdown-icon icon-tabler"/><span className="nav-dropdown-text">{t('common:logout')}</span>
                </NavDropdown.Item>
              </NavDropdown> :
              <Nav.Link as={Link} to="/login">
                <IconUser
                  size={26}
                />
              </Nav.Link>
              }
            </Nav>
          </Container>
        </Navbar>

        {/* routes */}
        
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Find />} />
          <Route path="/routes" element={<TripsWrapper/>} />
          <Route path="/order/:order_id" element={<OrderWrapper/>} />
          <Route path="/order/complete/:order_id/:hash" element={<ManualOrder/>} />
          <Route path="/order/sucess/:order_id/:hash" element={<MadeOrder/>} />
          <Route path="/order/failed/:order_id/:hash" element={<FailedOrder/>} />
          <Route path="/register" element={<IsLogin><Register /></IsLogin>} />
          <Route path="/password/reset" element={<Reset />} />
          <Route path="/email/verify/:token" element={<Validation />} />
          <Route path="/password/new/:token" element={<Reset2 />} />
          <Route path="/login" element={<IsLogin><Login /></IsLogin>} />
          <Route
            path="/account/trips"
            element={<RequireLogin><PanelTrips /></RequireLogin>}
          />
          <Route
            path="/account/settings"
            element={<RequireLogin><PanelSettings /></RequireLogin>}
          />
        </Routes>

        {/* footer */}
            <div className="footer">
        <Container className="mx-auto">
          <Row className="py-4">
            <Col className="text-center my-auto py-2" md={12} lg={4}>
              <img src={MareckiNeg} alt="Logo" className="footer-logo" />
            </Col>
            <Col className="text-center my-auto py-2" md={6} lg={4}>
              {configData.COMAPNY.NAME}
              <br />
              {configData.COMAPNY.ADDRESS}
              <hr className="d-block d-md-none w-50 mx-auto mb-0" />
            </Col>
            <Col className="text-center my-auto py-2" md={6} lg={4}>
              <a className="callLink" href={'tel:' + configData.COMAPNY.PHONE_1}>tel. kom.: {configData.COMAPNY.PHONE_1}</a>
              <br />
              <a className="callLink" href={'tel:' + configData.COMAPNY.PHONE_2}>tel. kom.: {configData.COMAPNY.PHONE_2}</a>
              <br />
              <a className="callLink" href={'tel:' + configData.COMAPNY.PHONE_4}>tel. kom.: {configData.COMAPNY.PHONE_3}</a>
              <br />
              <a className="callLink" href={'mailto:' + configData.COMAPNY.EMAIL}>email: {configData.COMAPNY.EMAIL}</a>
            </Col>
          </Row>
        </Container>
        <hr className="m-0" />

        <Container>
          <div className="d-flex mx-auto">
            <p className="my-auto Copyrights p-2">
              Powered by{" "}
              <a href="http://thecodefoxes.com" className="codeFoxes">
                TheCodeFoxes
              </a>
            </p>
            <p className="my-auto ms-auto Copyrights p-2">
              © {new Date().getFullYear()} Mareckitour.pl
            </p>
          </div>
        </Container>
        </div>
      </Router>
    </StorageContext.Provider>
  );
}

export default App;
