import React, { useEffect, useReducer, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import apiClient from "../../services/api";
import Loading from "../../components/loadPage/loadPage";
import Reservation from "./reservation";
import NotFound from "../404";
import customLocalStorage from "../../services/customlocalstorage";
import { useTranslation } from "react-i18next";
import { StorageContext } from "../../components/localStorageContext";

function TripsWrapper() {
    const [searchParams] = useSearchParams()
    const [reciving, setReciving] = useState([])
    const [git, setGit] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const {t} = useTranslation("routes");
    
    const [poiA, setPoiA] = useState({ lat: 0, lng: 0 })
    const [poiB, setPoiB] = useState({ lat: 0, lng: 0 })

    const [fromTitle, setFTitle] = useState({})
    const [toTitle, setTTitle] = useState({})
    const tripData = {
        dep_date: searchParams.get("dep_date"),
        toID: searchParams.get("toID"),
        fromID: searchParams.get("fromID"),
        passenger: searchParams.get("passengers")
    }

    const getPoiA = async () => {
        if (poiA.lat === 0 || poiA.lng === 0) {
            apiClient.get("/maps/lookup",
                {
                    params: {
                        id: searchParams.get("fromID"),
                

                    },
                }).then((response) => {
                    setFTitle(response.data.data)
                    setPoiA({ lat: response.data.data.position.lat, lng: response.data.data.position.lng })
                }).catch((error) => {
                    setGit(false)
                })
        }

    }
    const getPoiB = async () => {
        if (poiB.lat === 0 || poiB.lng === 0) {
            apiClient.get("/maps/lookup",
                {
                    params: {
                        id: searchParams.get("toID"),
                    

                    },
                }
            ).then((response) => {
                setTTitle(response.data.data)
                setPoiB({ lat: response.data.data.position.lat, lng: response.data.data.position.lng })
            }).catch((error) => {
                setGit(false)
            })
        }
    }
    const getData = async () => {
        await getPoiA()
        await getPoiB()
        apiClient.get("/maps/get-trips", {
            params: {
                from_hereid: tripData.fromID,
                to_hereid: tripData.toID,
                departure_date: tripData.dep_date,
                passengers: tripData.passenger,
            }
        }).then((response) => {
            if (response.status === 200) {
                setReciving(response.data)
                setGit(true)
                setLoading(false)
            }
            else {
                setGit(false)
            }
        }).catch((error) => {
            setLoading(false)
            setGit(false)
        })

    };
    useEffect(() => {
        getData()
    }, [])


    return (
        <div>
            {
                isLoading ?
                    <Loading title={t('loading')}/> :
                    git ? <Reservation tripsArray={reciving} poiA={poiA} toTripName={toTitle} tripInfo={fromTitle} poiB={poiB} /> : <NotFound />
            }
        </div>
    )
}

export default TripsWrapper;
