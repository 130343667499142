import { React, Component } from 'react';
import RealizedCard from './RealizedCard';
import { Container } from 'react-bootstrap';
import apiClient from '../../../../services/api';
import NoTrips from '../noTrips';
import ReactPaginate from 'react-paginate';
import '../../../../styles/paginate.css'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons';
class Realized extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasLoaded: false,
            dataArr: new Array,
            baseUrl: null,
            updatedKey: 1,
            forcePage: 0
        }
    }


    getApiData(page){

        this.state.hasLoaded = false
        this.forceUpdate() 

        if(page){
            this.state.baseUrl = "/order/user/fulfilled/paginated?page=" + (page + 1);
        }
        else{
            this.state.baseUrl = "/order/user/fulfilled/paginated";
        }

        apiClient.get(this.state.baseUrl, {}).then(response => {
            if (response.status === 200) {
                this.state.dataArr = response.data
                this.state.hasLoaded = true
                if(this.state.dataArr.data.length === 0)
                    this.state.ArrLen = true
                else
                    this.state.ArrLen = false
            }
        }).then(()=>{
           this.forceUpdate() 
           this.triggerReload()
        }).catch(error => {

        })
    }

    componentDidMount() {
        this.getApiData()
    }

    handlePageChange(e){
        this.getApiData(e.selected)
        this.state.forcePage = e.selected
    }
    triggerReload = () => {
        let newKey = Math.floor(Math.random() * 100); // make sure the key are never the same
            this.setState({updatedKey: newKey})
        }

    render() {

        return (
            <Container fluid>
                {this.state.hasLoaded ?
                    <div>
                        {
                            this.state.ArrLen ? 
                            <NoTrips/>
                            :
                            
                            <>
                            <RealizedCard key={this.state.updatedKey} dataReciv={this.state.dataArr.data}/> 
                            <ReactPaginate key={this.state.updatedKey+1}
                            activeClassName={'item page-active '}
                            breakClassName={'item break-me '}
                            breakLabel={'...'}
                            containerClassName={'pagination'}
                            disabledClassName={'disabled-page'}
                            marginPagesDisplayed={2}
                            nextClassName={"item next "}
                            nextLabel={<IconArrowRight style={{ fontSize: 18 }} className="arrow-next"/>}
                            onPageChange={(e) => this.handlePageChange(e)}
                            pageCount={this.state.dataArr.last_page}
                            forcePage={this.state.forcePage}
                            pageClassName={'item pagination-page '}
                            pageRangeDisplayed={2}
                            previousClassName={"item previous"}
                            previousLabel={<IconArrowLeft style={{ fontSize: 18 }} className="arrow-prev"/>}
                        />
                        </>
                        }
                    </div>
                    :
                    <div className='d-flex justify-content-center'><div className='loader-circle'/></div>
                }

            </Container>
        )
    }
}

export default Realized;
